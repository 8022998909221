import { ReactNode } from 'react';
import routes from '@/constants/routes.ts';
import { externalLinks } from '@/constants/externalLinks.ts';
import LuggageIcon from '@/assets/Icons/Luggage.svg?react';
import UsersTravelers from '@/assets/Icons/Users-Travelers.svg?react';
import CompanyIcon from '@/assets/Icons/Company.svg?react';
import CheckApprovalIcon from '@/assets/Icons/Check-Approval.svg?react';
import DocListIcon from '@/assets/Icons/Doc-List.svg?react';
import StatisticsIcon from '@/assets/Icons/Statistics.svg?react';
import PolicyDocIcon from '@/assets/Icons/Policy-Doc.svg?react';
import UserTravelerGroup from '@/assets/Icons/User-Traveler-Group.svg?react';
import BuildingIcon from '@/assets/Icons/Building.svg?react';
import { Roles } from '@/api/user.ts';

interface MenuLink {
	label: string;
	route: string;
	icon?: ReactNode;
}

interface SubmenuSection {
	header: string;
	links: MenuLink[];
}

export interface HeaderMenu {
	label: string;
	route?: string;
	submenu?: SubmenuSection[];
	match?: string;
}

type RoleBasedMenus = {
	[key in Roles | string]: HeaderMenu[];
};

const headerMenuPerRole: RoleBasedMenus = {
	[Roles.TmcAdmin]: [
		{ label: 'Climate', route: routes.dashboard },
		{ label: 'My Climate', route: routes.myClimate },
		{ label: 'Climate Pro', route: routes.climateProSetting, match: routes.climatePro },
		{ label: 'Book', route: externalLinks.spotnana.book },
		{
			label: 'Trips',
			submenu: [
				{
					header: 'Individual',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.myTrips, icon: <LuggageIcon /> },
						{
							label: 'Group Trips',
							route: externalLinks.spotnana.groupTrips,
							icon: <UsersTravelers />,
						},
					],
				},
				{
					header: 'Company',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.companyTrips, icon: <CompanyIcon /> },
						{
							label: 'Approvals',
							route: externalLinks.spotnana.companyApprovals,
							icon: <CheckApprovalIcon />,
						},
						{
							label: 'Events',
							route: externalLinks.spotnana.events,
							icon: <UserTravelerGroup />,
						},
					],
				},
			],
		},
		{
			label: 'Analytics',
			submenu: [
				{
					header: 'Reports',
					links: [
						{
							label: 'My reports',
							route: externalLinks.spotnana.myReports,
							icon: <StatisticsIcon />,
						},
						{
							label: 'Company reports',
							route: externalLinks.spotnana.companyReports,
							icon: <DocListIcon />,
						},
					],
				},
				{
					header: 'Safety',
					links: [
						{
							label: 'Travellers',
							route: externalLinks.spotnana.travellersSafety,
							icon: <LuggageIcon />,
						},
					],
				},
			],
		},
		{
			label: 'Program',
			submenu: [
				{
					header: 'Settings',
					links: [
						{ label: 'Company', route: externalLinks.spotnana.companySettings, icon: <CompanyIcon /> },
						{ label: 'TMC Settings', route: externalLinks.spotnana.tmcSettings, icon: <BuildingIcon /> },
						{ label: 'Users', route: externalLinks.spotnana.usersSettings, icon: <UsersTravelers /> },
						{ label: 'Policies', route: externalLinks.spotnana.policies, icon: <PolicyDocIcon /> },
					],
				},
			],
		},
		{ label: 'Support', route: externalLinks.spotnana.support },
	],
	[Roles.TmcAgent]: [
		{ label: 'Climate', route: routes.dashboard },
		{ label: 'My Climate', route: routes.myClimate },
		{ label: 'Book', route: externalLinks.spotnana.book },
		{
			label: 'Trips',
			submenu: [
				{
					header: 'Individual',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.myTrips, icon: <LuggageIcon /> },
						{
							label: 'Group Trips',
							route: externalLinks.spotnana.groupTrips,
							icon: <UsersTravelers />,
						},
					],
				},
				{
					header: 'Company',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.companyTrips, icon: <CompanyIcon /> },
						{
							label: 'Approvals',
							route: externalLinks.spotnana.companyApprovals,
							icon: <CheckApprovalIcon />,
						},
						{
							label: 'Events',
							route: externalLinks.spotnana.events,
							icon: <UserTravelerGroup />,
						},
					],
				},
			],
		},
		{
			label: 'Analytics',
			submenu: [
				{
					header: 'Reports',
					links: [
						{
							label: 'My reports',
							route: externalLinks.spotnana.myReports,
							icon: <StatisticsIcon />,
						},
						{
							label: 'Company reports',
							route: externalLinks.spotnana.companyReports,
							icon: <DocListIcon />,
						},
					],
				},
				{
					header: 'Safety',
					links: [
						{
							label: 'Travellers',
							route: externalLinks.spotnana.travellersSafety,
							icon: <LuggageIcon />,
						},
					],
				},
			],
		},
		{
			label: 'Program',
			submenu: [
				{
					header: 'Settings',
					links: [
						{ label: 'Company', route: externalLinks.spotnana.companySettings, icon: <CompanyIcon /> },
						{ label: 'TMC Settings', route: externalLinks.spotnana.tmcSettings, icon: <BuildingIcon /> },
						{ label: 'Users', route: externalLinks.spotnana.usersSettings, icon: <UsersTravelers /> },
						{ label: 'Policies', route: externalLinks.spotnana.policies, icon: <PolicyDocIcon /> },
					],
				},
			],
		},
		{ label: 'Support', route: externalLinks.spotnana.support },
	],
	[Roles.CompanyAdmin]: [
		{ label: 'Climate', route: routes.dashboard },
		{ label: 'My Climate', route: routes.myClimate },
		{ label: 'Climate Pro', route: routes.climateProSetting, match: routes.climatePro },
		{ label: 'Book', route: externalLinks.spotnana.book },
		{
			label: 'Trips',
			submenu: [
				{
					header: 'Individual',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.myTrips, icon: <LuggageIcon /> },
						{
							label: 'Group Trips',
							route: externalLinks.spotnana.groupTrips,
							icon: <UsersTravelers />,
						},
					],
				},
				{
					header: 'Company',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.companyTrips, icon: <CompanyIcon /> },
						{
							label: 'Approvals',
							route: externalLinks.spotnana.companyApprovals,
							icon: <CheckApprovalIcon />,
						},
					],
				},
			],
		},
		{
			label: 'Analytics',
			submenu: [
				{
					header: 'Reports',
					links: [
						{
							label: 'My reports',
							route: externalLinks.spotnana.myReports,
							icon: <StatisticsIcon />,
						},
						{
							label: 'Company reports',
							route: externalLinks.spotnana.companyReports,
							icon: <DocListIcon />,
						},
					],
				},
				{
					header: 'Safety',
					links: [
						{
							label: 'Travellers',
							route: externalLinks.spotnana.travellersSafety,
							icon: <LuggageIcon />,
						},
					],
				},
			],
		},
		{
			label: 'Program',
			submenu: [
				{
					header: 'Settings',
					links: [
						{ label: 'Company', route: externalLinks.spotnana.companySettings, icon: <CompanyIcon /> },
						{ label: 'Users', route: externalLinks.spotnana.usersSettings, icon: <UsersTravelers /> },
						{ label: 'Policies', route: externalLinks.spotnana.policies, icon: <PolicyDocIcon /> },
					],
				},
			],
		},
		{ label: 'Support', route: externalLinks.spotnana.support },
	],
	[Roles.CompanyTravelArranger]: [
		{ label: 'Climate', route: routes.dashboard },
		{ label: 'My Climate', route: routes.myClimate },
		{ label: 'Book', route: externalLinks.spotnana.book },
		{
			label: 'Trips',
			submenu: [
				{
					header: 'Individual',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.myTrips, icon: <LuggageIcon /> },
						{
							label: 'Group Trips',
							route: externalLinks.spotnana.groupTrips,
							icon: <UsersTravelers />,
						},
					],
				},
			],
		},
		{
			label: 'Analytics',
			route: externalLinks.spotnana.myReports,
		},
		{ label: 'Support', route: externalLinks.spotnana.support },
	],
	[Roles.TravelArranger]: [
		{ label: 'Climate', route: routes.dashboard },
		{ label: 'My Climate', route: routes.myClimate },
		{ label: 'Book', route: externalLinks.spotnana.book },
		{
			label: 'Trips',
			submenu: [
				{
					header: 'Individual',
					links: [
						{ label: 'Trips', route: externalLinks.spotnana.myTrips, icon: <LuggageIcon /> },
						{
							label: 'Group Trips',
							route: externalLinks.spotnana.groupTrips,
							icon: <UsersTravelers />,
						},
					],
				},
			],
		},
		{
			label: 'Analytics',
			route: externalLinks.spotnana.myReports,
		},
		{ label: 'Support', route: externalLinks.spotnana.support },
	],
	default: [
		{ label: 'Climate', route: routes.dashboard },
		{ label: 'My Climate', route: routes.myClimate },
		{ label: 'Book', route: externalLinks.spotnana.book },
		{ label: 'Trips', route: externalLinks.spotnana.myTrips },
		{ label: 'Analytics', route: externalLinks.spotnana.myReports },
		{ label: 'Support', route: externalLinks.spotnana.support },
	],
};

export const commonLinks = [
	{ to: externalLinks.spotnana.myTrips, label: 'Trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'Approvals' },
	{ to: externalLinks.spotnana.myProfile, label: 'My Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
];

export default headerMenuPerRole;
