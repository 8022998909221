import React from 'react';
import { Link } from 'react-router-dom';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './ContentCard.module.scss';
import { truncate } from 'lodash';
import MarkerPinIcon from '@/assets/Icons/Marker-Pin.svg?react';
import { useModalStore } from '@/store/useModalStore.ts';
import { Moment } from 'moment';
import GooglePlaceReviewData = App.Data.GooglePlaceReviewData;
import StarsRating from '@/pages/MyClimate/UpcomingTrips/StarsRating.tsx';
import PriceRange from '@/pages/MyClimate/UpcomingTrips/PriceRange.tsx';
import ReviewsModal from '@/pages/MyClimate/UpcomingTrips/ReviewsModal.tsx';
import AddToCalendarModal from '@/pages/MyClimate/UpcomingTrips/AddToCalendarModal.tsx';

interface ContentCardProps {
	image: React.ReactNode;
	title: string;
	rating?: number;
	expensiveness?: number;
	website: string | null;
	tags?: string[] | null;
	description: string | null;
	location?: { latitude: number; longitude: number };
	googlePlaceId?: string;
	distance: string;
	reviews?: GooglePlaceReviewData[] | null;
	onSeeLocationClick?: (location: { lat: number; lng: number }) => void;
	arrivalAt: Moment | null;
}

const ContentCard: React.FC<ContentCardProps> = ({
	image,
	title,
	rating,
	expensiveness,
	website,
	description,
	location,
	googlePlaceId,
	distance,
	reviews,
	onSeeLocationClick,
	arrivalAt,
}) => {
	const { openModal } = useModalStore();
	const modalReviewsId = `reviews-${title}`;
	const modalCalendarId = `calendar-${title}`;
	const truncatedDescription =
		description &&
		truncate(description, {
			length: 215,
			separator: /,?\.* +/,
		});

	return (
		<FlexBlock flexDirection="column" className={styles.contentCardWrapper}>
			<div className={styles.imageWrapper}>{image}</div>
			<h3>{title}</h3>
			{(!!rating || !!expensiveness || !!website) && (
				<FlexBlock justifyContent="space-between" alignItems="center" className={styles.moreInfo}>
					<FlexBlock alignItems="center" columnGap="10px" rowGap="10px">
						{!!rating && <StarsRating rating={rating} />}
						{!!expensiveness && <PriceRange priceLevel={expensiveness} />}
					</FlexBlock>
					{!!website && (
						<Link target="_blank" to={website} className={styles.website}>
							Visit a website
						</Link>
					)}
				</FlexBlock>
			)}
			<div>
				{!!truncatedDescription && <p className={styles.desc}>{truncatedDescription}</p>}
				{!!reviews && (
					<Button
						color="transparent"
						size="small"
						onClick={() => openModal(modalReviewsId)}
						className={styles.readReviews}
					>
						Read this and more reviews
					</Button>
				)}
			</div>
			<div className={styles.ctaSection}>
				<FlexBlock alignItems="center" justifyContent="space-between" className={styles.locationAndDistance}>
					{!!location && onSeeLocationClick && (
						<button onClick={() => onSeeLocationClick({ lat: location.latitude, lng: location.longitude })}>
							<MarkerPinIcon /> <span>See location on map</span>
						</button>
					)}
					<p>{distance}</p>
				</FlexBlock>
				<Button
					color="darkGreen"
					fullWidth
					onClick={() => openModal(modalCalendarId)}
					className={styles.addToCalendarBttn}
				>
					Add to Calendar
				</Button>
			</div>
			{!!reviews && reviews.length > 0 && (
				<ReviewsModal modalId={modalReviewsId} data={reviews.map(review => ({ ...review, name: title }))} />
			)}
			{googlePlaceId && (
				<AddToCalendarModal modalId={modalCalendarId} googlePlaceId={googlePlaceId} arrivalAt={arrivalAt} />
			)}
		</FlexBlock>
	);
};

export default ContentCard;
