import { FC } from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

interface PriceRangeProps {
	priceLevel: number;
}

const PriceRange: FC<PriceRangeProps> = ({ priceLevel }) => {
	if (priceLevel) {
		return (
			<FlexBlock flex={1}>
				{Array.from({ length: priceLevel }, (_, i) => i).map((_, index) => (
					<svg
						key={index}
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 18 18"
						fill="none"
					>
						<path
							d="M9.00078 2.56934C5.45478 2.56934 2.57031 5.45419 2.57031 9.00001C2.57031 12.5458 5.45478 15.4303 9.00078 15.4303C12.5466 15.4303 15.4312 12.5458 15.4312 9.00001C15.4312 5.45419 12.5466 2.56934 9.00078 2.56934ZM9.00078 14.4159C6.01422 14.4159 3.58461 11.9864 3.58461 8.99998C3.58461 6.01324 6.01422 3.58363 9.00078 3.58363C11.9873 3.58363 14.4169 6.01324 14.4169 8.99998C14.4169 11.9864 11.9873 14.4159 9.00078 14.4159Z"
							fill="#798B9D"
						/>
						<path
							d="M7.98408 7.73946C7.98408 7.33263 8.5791 7.26316 9.09523 7.26316C9.58168 7.26316 10.2464 7.49115 10.8022 7.77929L10.9111 6.65798C10.6331 6.49908 9.98834 6.3105 9.33337 6.27109L9.49211 5.12988H8.43035L8.58929 6.27109C7.22006 6.3999 6.77344 7.19366 6.77344 7.85854C6.77344 9.53538 9.89876 9.17855 9.89876 10.2101C9.89876 10.5974 9.53194 10.736 8.88679 10.736C8.01379 10.736 7.35865 10.4287 7.02154 10.1109L6.85268 11.3712C7.17049 11.5598 7.81508 11.7284 8.58929 11.7683L8.43035 12.8697H9.49211L9.33337 11.7581C10.9408 11.6191 11.2285 10.7657 11.2285 10.2003C11.2285 8.20602 7.98408 8.70186 7.98408 7.73946Z"
							fill="#798B9D"
						/>
					</svg>
				))}
			</FlexBlock>
		);
	}

	return null;
};

export default PriceRange;
