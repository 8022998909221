import { create } from 'zustand';
import { WhoamiResponse } from '@/api/auth.ts';

interface UseWhoamiStore extends WhoamiResponse {
	loggedIn: undefined | boolean;
	setWhoami: (whoami: WhoamiResponse) => void;
	setWeGlotLanguageCode: (weGlotLanguageCode: string) => void;
}

export const useWhoamiStore = create<UseWhoamiStore>(set => ({
	loggedIn: undefined,
	setWhoami: whoami =>
		set({
			loggedIn: !!whoami.user,
			...whoami,
		}),
	setWeGlotLanguageCode: weGlotLanguageCode =>
		set({
			weGlotLanguageCode,
		}),
}));
