import React, { useEffect } from 'react';
import styles from './Metrics.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import IncreaseArrow from '@/assets/Icons/Arrow-Increase.svg?react';
import DecreaseArrow from '@/assets/Icons/Arrow-Decrease.svg?react';
import { CountUp } from 'use-count-up';
import { isEmpty, isNumber } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { periodOptions } from '@/constants/climatePro.ts';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getEntitiesFilters, getMetrics } from '@/api/climatePro.ts';
import { Form, FormikProvider, useFormik } from 'formik';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import Co2SourceModelClass = App.Enums.Co2SourceModelClass;
import Co2MetricPeriod = App.Enums.Co2MetricPeriod;
import { Option } from '@/components/ui/Select/Select.tsx';

export const transformDataToGroupedOptionsArray = (
	data: {
		label: string;
		value: string;
		items: Option[];
	}[],
) => {
	const groupedOptionsArray: Option[] = [];

	data &&
		data.forEach(group => {
			// Make object for group
			groupedOptionsArray.push({
				label: group.label,
				value: group.value,
				isGroup: true,
			});

			// Transform items within the group
			group.items.forEach(item => {
				groupedOptionsArray.push({
					label: item.label,
					value: `${group.value}.${item.value}`,
				});
			});
		});

	return groupedOptionsArray;
};

const Metrics: React.FC = () => {
	const { data: entitiesFilter } = useQuery({
		queryKey: [queryKeys.entitiesFilter],
		queryFn: getEntitiesFilters,
	});

	const formik = useFormik({
		initialValues: {
			period: 'last-12-months',
			sourceModelClass: entitiesFilter?.entities?.[0]?.value || '',
			sourceModelId:
				entitiesFilter?.entities?.[0]?.value + '.' + entitiesFilter?.entities?.[0]?.items?.[0]?.value || '',
		},
		enableReinitialize: true,
		onSubmit: () => {},
	});

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKeys.metrics, formik.values.sourceModelId],
		queryFn: () => {
			const [sourceModelClass, sourceModelId] = formik.values.sourceModelId.split('.');
			return getMetrics({
				period: formik.values.period as Co2MetricPeriod,
				sourceModelClass: sourceModelClass as Co2SourceModelClass,
				sourceModelId: +sourceModelId,
			});
		},
		enabled: !isEmpty(entitiesFilter?.entities) && !!formik.values.sourceModelId,
	});

	useEffect(() => {
		refetch();
	}, [formik.values]);

	return (
		<FlexBlock flexDirection="column" rowGap="32px" className={styles.metricsWrapper}>
			<FlexBlock justifyContent="space-between" alignItems="center">
				<h1>CO2 metrics</h1>
				<FormikProvider value={formik}>
					<Form onSubmit={formik.handleSubmit}>
						<FlexBlock columnGap="20px">
							<FormikSelect
								searchable
								name="sourceModelId"
								options={transformDataToGroupedOptionsArray(entitiesFilter?.entities || [])}
								className={styles.selectDropdown}
							/>
							<FormikSelect
								name="period"
								options={periodOptions}
								placeholder="Choose period"
								className={styles.selectDropdown}
							/>
						</FlexBlock>
					</Form>
				</FormikProvider>
			</FlexBlock>
			<FlexBlock flexWrap="wrap" columnGap="32px" rowGap="32px" className={styles.metricsCards}>
				{!isLoading && data && data?.metrics?.length > 0 ? (
					data?.metrics.map((data, index) => (
						<div className={styles.metricsCard} key={index}>
							<h4>{data.label}</h4>
							<p className={styles.amount}>
								{isNumber(data.value) ? (
									<CountUp end={data.value} isCounting duration={0.75} />
								) : (
									data.value
								)}
							</p>
							<div className={styles.percentage}>
								<span className={data.isPositive ? styles.positive : styles.negative}>
									{data.isIncreasing ? <IncreaseArrow /> : <DecreaseArrow />} {data.percentageChange}%
								</span>{' '}
								year-over-year
							</div>
						</div>
					))
				) : (
					<>
						{Array.from({ length: 9 }).map((_, index) => (
							<div key={index} className={styles.metricsCard}>
								<Skeleton className={styles.skeletonTitle} />
								<Skeleton className={styles.skeletonValue} />
								<Skeleton className={styles.skeletonAddInfo} />
							</div>
						))}
					</>
				)}
			</FlexBlock>
		</FlexBlock>
	);
};

export default Metrics;
