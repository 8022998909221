import React, { ReactNode } from 'react';
import styles from './InfoStatus.module.scss';
import InfoIcon from '@/assets/Icons/Info.svg?react';
import classNames from 'classnames';

interface InfoStatusProps {
	icon?: ReactNode;
	text: string;
	status?: 'success' | 'warning' | 'info';
	className?: string;
}

const InfoStatus: React.FC<InfoStatusProps> = ({ icon = <InfoIcon />, text, status = 'info', className }) => {
	return (
		<div className={classNames(styles.infoStatus, styles[status], className)}>
			{icon}
			{text}
		</div>
	);
};

export default InfoStatus;
