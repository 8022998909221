import React, { FocusEventHandler, useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import moment from 'moment';
import styles from './CalendarPicker.module.scss';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import ArrowLeft from '@/assets/Icons/Arrow-Left.svg?react';
import CalendarIcon from '@/assets/Icons/Calendar-Schedule-1.svg?react';
import classNames from 'classnames';

interface CustomDate {
	month: number;
	day: number;
}

export interface CalendarPickerProps {
	onSelect: (date: CustomDate) => void;
	name?: string;
	label?: string;
	className?: string;
	errorMessage?: string;
	touched?: boolean;
	value?: CustomDate;
	onBlur?: FocusEventHandler | undefined;
	popoverPosition?: 'top' | 'bottom';
}

const CalendarPicker: React.FC<CalendarPickerProps> = ({
	onSelect,
	label,
	className,
	value,
	errorMessage,
	touched,
	onBlur,
	popoverPosition = 'bottom',
}) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

	useEffect(() => {
		if (value) {
			setSelectedDate(
				moment()
					.month(value.month - 1)
					.date(value.day),
			);
		} else {
			setSelectedDate(null);
		}
	}, [value]);

	const handleDateSelect = (day: number) => {
		const newDate = moment()
			.month(selectedDate?.month() || moment().month())
			.date(day);
		setSelectedDate(newDate);
		onSelect({ month: newDate.month() + 1, day: newDate.date() });
		setIsPopoverOpen(false);
	};

	const renderCalendar = () => {
		const daysInMonth = selectedDate ? selectedDate.daysInMonth() : moment().daysInMonth();
		const calendarDays = [];
		for (let i = 1; i <= daysInMonth; i++) {
			calendarDays.push(
				<div
					key={i}
					className={`${styles.day} ${selectedDate && selectedDate.date() === i ? styles.selected : ''}`}
					onClick={() => handleDateSelect(i)}
				>
					{i}
				</div>,
			);
		}

		return (
			<div className={classNames(styles.calendar, className)}>
				<div className={styles.header}>
					<span>{selectedDate ? selectedDate.format('MMMM') : moment().format('MMMM')}</span>
					<div className={styles.buttons}>
						<button
							onClick={() => setSelectedDate(selectedDate ? selectedDate.clone().subtract(1, 'month') : moment().subtract(1, 'month'))}
						>
							<ArrowLeft />
						</button>
						<button onClick={() => setSelectedDate(selectedDate ? selectedDate.clone().add(1, 'month') : moment().add(1, 'month'))}>
							<ArrowRight />
						</button>
					</div>
				</div>
				<div className={styles.daysGrid}>{calendarDays}</div>
			</div>
		);
	};

	return (
		<div className={styles.calendarContainer}>
			{label && <label className={styles.label}>{label}</label>}
			<Popover
				isOpen={isPopoverOpen}
				positions={[popoverPosition]}
				content={renderCalendar()}
				onClickOutside={() => setIsPopoverOpen(false)}
				containerClassName={styles.calendarContainer}
			>
				<div className={styles.selectButtonWrapper}>
					<button className={styles.selectButton} onClick={() => setIsPopoverOpen(!isPopoverOpen)} onBlur={onBlur}>
						{selectedDate ? selectedDate.format('MMMM Do') : 'Select'}
						<div className={styles.calendarIcon}>
							<CalendarIcon />
						</div>
					</button>
					{!!errorMessage && touched && <div className={styles.errorMessage}>{errorMessage}</div>}
				</div>
			</Popover>
		</div>
	);
};

export default CalendarPicker;
