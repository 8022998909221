import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './Select.module.scss';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';
import CheckmarkIcon from '@/assets/Icons/Done-Checkmark.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import OutsideClickHandler from 'react-outside-click-handler';
import SearchIcon from '@/assets/Icons/Search.svg?react';
import { isEmpty, toLower } from 'lodash';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import { ListChildComponentProps, VariableSizeList as List } from 'react-window';
import { calculateTextHeight } from '@/utils/domUtils.ts';
import Skeleton from 'react-loading-skeleton';

export type Value = string | number | undefined;

export interface Option {
	value: Value;
	label: string;
	isGroup?: boolean;
}

export interface SelectProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: Value;
	onChange: (newValue: Value) => void;
	options: Option[] | Record<string, Option[]>;
	errorMessage?: string;
	touched?: boolean;
	disabled?: boolean;
	name?: string;
	onBlur?: () => void;
	position?: 'top' | 'bottom';
	tableSelect?: boolean;
	searchable?: boolean;
}

const Row: React.FC<ListChildComponentProps<any>> = React.memo(({ index, style, data }) => {
	const { options, handleOptionClick, selectedValue } = data;
	const option = options[index];

	if (!option) return null;

	if (option.isGroup) {
		return (
			<div style={style} className={styles.groupLabel}>
				{option.label}
			</div>
		);
	}

	return (
		<div
			style={style}
			className={classNames(styles.rowWrapper, {
				[styles.selected]: option.value === selectedValue,
			})}
		>
			<FlexBlock
				justifyContent="space-between"
				alignItems="center"
				className={styles.option}
				onClick={() => handleOptionClick(option.value)}
			>
				{option.label}
				{option.value === selectedValue && <CheckmarkIcon />}
			</FlexBlock>
		</div>
	);
});

const Select: React.FC<SelectProps> = ({
	label,
	onChange,
	placeholder,
	className,
	options,
	value,
	errorMessage,
	touched,
	disabled,
	name,
	onBlur,
	position = 'bottom',
	tableSelect,
	searchable = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const containerRef = useRef<HTMLDivElement>(null);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const handleOpen = () => {
		if (!disabled) setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setSearchQuery('');
		onBlur && onBlur();
	};

	const handleOptionClick = (newValue: Value) => {
		onChange(newValue);
		handleClose();
	};

	useEffect(() => {
		if (containerRef.current) {
			setContainerWidth(containerRef.current.offsetWidth);
		}
	}, []);

	const getDisplayedOptions = (): Option[] => {
		if (Array.isArray(options)) {
			return searchQuery
				? options.filter((option, index, array) => {
						const matchesSearch = toLower(option.label).includes(toLower(searchQuery.trim()));
						const isCurrentGroup = option.isGroup;

						// Check if the current option is a group
						if (isCurrentGroup) {
							// Determine if there are any matching options in this group
							const nextGroupIndex = array.findIndex((opt, i) => i > index && opt.isGroup);
							const groupOptions = array.slice(
								index + 1,
								nextGroupIndex === -1 ? undefined : nextGroupIndex,
							);
							const hasMatchesInGroup = groupOptions.some(opt =>
								toLower(opt.label).includes(toLower(searchQuery.trim())),
							);

							// Only include the group if it has matching options
							return hasMatchesInGroup;
						}

						return matchesSearch;
					})
				: options;
		} else {
			const flattenedOptions: Option[] = [];
			Object.entries(options).forEach(([groupLabel, groupOptions]) => {
				const filtered = searchQuery
					? groupOptions.filter(option => toLower(option.label).includes(toLower(searchQuery.trim())))
					: groupOptions;
				if (filtered.length > 0) {
					flattenedOptions.push({ value: groupLabel, label: groupLabel, isGroup: true });
					flattenedOptions.push(...filtered);
				}
			});
			return flattenedOptions;
		}
	};

	// Dynamic row rendering logic
	const displayedOptions = getDisplayedOptions();

	const renderOptions = () => {
		const calculateListHeight = () => {
			if (displayedOptions.length <= 5) {
				let elementsHeight = 0;
				displayedOptions.forEach(option => {
					elementsHeight += calculateTextHeight(option.label, containerWidth);
				});
				return elementsHeight > 220 ? 220 : elementsHeight;
			}
			return searchable ? 220 : 272;
		};

		return (
			<List
				height={calculateListHeight()}
				itemCount={displayedOptions.length}
				itemSize={index => calculateTextHeight(displayedOptions[index].label, containerWidth)}
				width="100%"
				itemData={{
					options: displayedOptions,
					handleOptionClick,
					selectedValue: value,
				}}
			>
				{Row}
			</List>
		);
	};

	return (
		<FlexBlock
			justifyContent="space-between"
			flexDirection="column"
			className={classNames(styles.wrapper, className)}
		>
			{!!label && <span className={styles.label}>{label}</span>}
			<div className={styles.selectWrapper}>
				<div
					ref={containerRef}
					className={classNames(styles.select, {
						[styles.disabled]: disabled,
						[styles.tableSelect]: tableSelect,
					})}
					onClick={handleOpen}
					role="button"
					tabIndex={0}
					aria-disabled={disabled}
					data-name={name}
				>
					<span className={styles.selectedOptionLabel}>
						{value !== undefined
							? Array.isArray(options)
								? options.find(option => option.value === value)?.label || (
										<Skeleton height={17} width="100%" />
									)
								: Object.values(options)
										.flat()
										.find(option => option.value === value)?.label || (
										<Skeleton height={17} width="100%" />
									)
							: placeholder || 'Select...'}
					</span>
					<div className={classNames(styles.icon, { [styles.open]: isOpen })}>
						<ArrowDown />
					</div>
				</div>

				{isOpen && (
					<OutsideClickHandler onOutsideClick={() => setTimeout(handleClose, 10)}>
						<div
							className={classNames(
								styles.options,
								position === 'top' && styles.top,
								tableSelect && styles.tableSelectOptions,
							)}
						>
							{searchable && (
								<div className={styles.search}>
									<SearchIcon className={styles.searchIcon} />
									<input
										type="text"
										value={searchQuery}
										autoFocus
										placeholder="Search..."
										onChange={e => setSearchQuery(e.target.value)}
									/>
									{!isEmpty(searchQuery) && (
										<CloseIcon
											className={styles.closeIcon}
											onClick={e => {
												e.stopPropagation();
												setSearchQuery('');
											}}
										/>
									)}
								</div>
							)}

							{isEmpty(displayedOptions) ? (
								<div className={styles.noResults}>No results found</div>
							) : (
								renderOptions()
							)}
						</div>
					</OutsideClickHandler>
				)}
			</div>
			{!!errorMessage && touched && <span className={styles.errorMessage}>{errorMessage}</span>}
		</FlexBlock>
	);
};

export default Select;
