import React, { createContext, useContext, useState, ReactNode, useCallback, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import styles from './Flyout.module.scss';

interface FlyoutContextProps {
	closeFlyout: () => void;
}

interface FlyoutCloseButtonProps {
	className?: string;
}

interface FlyoutProps {
	id: string;
	button: ReactNode;
	className?: string;
	children?: ReactNode;
	flyoutWrapperClassName?: string;
}

const FlyoutContext = createContext<FlyoutContextProps | null>(null);

const useFlyout = () => {
	const context = useContext(FlyoutContext);
	if (!context) {
		throw new Error('useFlyout must be used within a Flyout');
	}
	return context;
};

export const FlyoutCloseButton: React.FC<FlyoutCloseButtonProps> = ({ className }) => {
	const { closeFlyout } = useFlyout();
	return (
		<button className={classNames(styles.closeFlyoutBttn, className)} onClick={closeFlyout} aria-label="Close">
			<CloseIcon />
		</button>
	);
};

const Flyout: React.FC<FlyoutProps> = ({ id, button, className, children, flyoutWrapperClassName }) => {
	const [openFlyoutId, setOpenFlyoutId] = useState<string | null>(null);
	const [visible, setVisible] = useState(false);
	const sideMenuRef = useRef<HTMLDivElement>(null);

	const isFlyoutOpen = openFlyoutId === id;

	const openFlyout = useCallback(() => {
		setOpenFlyoutId(id);
		// Wait one frame before showing to ensure initial state is rendered
		requestAnimationFrame(() => {
			setVisible(true);
		});
	}, [id]);

	const closeFlyout = useCallback(() => {
		// Start closing animation
		setVisible(false);
	}, []);

	// If we rely on CSS transitions, listen for the end of the close transition
	useEffect(() => {
		const sideMenuEl = sideMenuRef.current;
		if (!sideMenuEl) return;

		const handleTransitionEnd = (e: TransitionEvent) => {
			if (e.target === sideMenuEl && !visible) {
				// Only reset the ID after the close animation completes
				setOpenFlyoutId(null);
			}
		};

		sideMenuEl.addEventListener('transitionend', handleTransitionEnd);
		return () => {
			sideMenuEl.removeEventListener('transitionend', handleTransitionEnd);
		};
	}, [visible]);

	const toggleFlyout = () => {
		if (isFlyoutOpen && visible) {
			closeFlyout();
		} else {
			openFlyout();
		}
	};

	return (
		<div className={flyoutWrapperClassName}>
			<span onClick={toggleFlyout}>{button}</span>
			{openFlyoutId === id && (
				<FlyoutContext.Provider value={{ closeFlyout }}>
					{ReactDOM.createPortal(
						<div
							ref={sideMenuRef}
							className={classNames(styles.sideMenu, {
								[styles.visibleSideMenu]: visible,
							})}
						>
							<div className={styles.overlay} onClick={closeFlyout}></div>
							<div className={classNames(styles.flyoutContent, className)}>{children}</div>
						</div>,
						document.body,
					)}
				</FlyoutContext.Provider>
			)}
		</div>
	);
};

export default Flyout;
