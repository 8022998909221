import EarthIcon from '@/assets/Icons/Earth-Spotnana.svg?react';
import MetricsIcon1 from '@/assets/Icons/Metrics-1.svg?react';
import MetricsIcon2 from '@/assets/Icons/Metrics-2.svg?react';
import MetricsIcon3 from '@/assets/Icons/Metrics-3.svg?react';
import MetricsIcon4 from '@/assets/Icons/Metrics-4.svg?react';
import CertificateIcon from '@/assets/Icons/Certificate.svg?react';
import DownloadFileIcon from '@/assets/Icons/Download-File.svg?react';

export const climateProSideMenuItems = {
	'CO2 budget': {
		icon: <EarthIcon />,
		submenu: {
			Setting: { path: '/climate-pro/setting' },
			Allocation: { path: '/climate-pro/allocation' },
			'Excess alerts': { path: '/climate-pro/excess-alerts' },
		},
	},
	'CO2 factor framework': {
		path: '/climate-pro/factor-framework',
		icon: <MetricsIcon1 />,
	},
	'CO2 metrics': {
		path: '/climate-pro/metrics',
		icon: <MetricsIcon2 />,
	},
	'CO2 dashboard': {
		path: '/climate-pro/dashboard',
		icon: <MetricsIcon3 />,
	},
	'AI predictive analytics': {
		path: '/climate-pro/analytics',
		icon: <MetricsIcon4 />,
	},
	Certificates: {
		path: '/climate-pro/certificates',
		icon: <CertificateIcon />,
	},
	'CO2 report downloads': {
		path: '/climate-pro/report',
		icon: <DownloadFileIcon />,
	},
};
