import React from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import { Form, Formik } from 'formik';
import { addToCalendarSchema } from '@/validations/auth.ts';
import FormikTimeInput from '@/components/Formik/FormikTimeInput.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './AddToCalendarModal.module.scss';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import FormikDatePicker from '@/components/Formik/FormikDatePicker.tsx';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import { addEventToCalendar } from '@/api/trips.ts';
import moment, { Moment } from 'moment';
import { DateValue } from 'react-aria-components';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import { parseDate } from '@internationalized/date';

interface AddToCalendarModalProps {
	modalId: string;
	googlePlaceId: string;
	arrivalAt: Moment | null;
}

const durationOptions = [
	{ value: 15, label: '15 min' },
	{
		value: 30,
		label: '30 min',
	},
	{ value: 45, label: '45 min' },
	{ value: 60, label: '1 h' },
	{ value: 90, label: '1.5 h' },
	{ value: 120, label: '2 h' },
	{ value: 150, label: '2.5 h' },
	{ value: 180, label: '3 h' },
];

const AddToCalendarModal: React.FC<AddToCalendarModalProps> = ({ modalId, googlePlaceId, arrivalAt }) => {
	const initialValues = {
		date: arrivalAt ? parseDate(arrivalAt.format('YYYY-MM-DD')) : null,
		time: arrivalAt ? arrivalAt.format('HH:mm:ss') : null,
		duration: 30,
	};

	const handleSubmit = async (values: { date: DateValue | null; time: string | null; duration: number }) => {
		const [hour, minute] = values.time ? values.time.split(':').map(Number) : [0, 0];

		const requestData = {
			googlePlaceId: googlePlaceId,
			startAt: moment
				.utc({
					year: values.date?.year || 0,
					month: (values.date?.month || 1) - 1,
					day: values.date?.day || 1,
					hour,
					minute,
				})
				.toISOString(),
			duration: values.duration,
		};

		addEventToCalendar(requestData);
	};

	return (
		<Modal name={modalId} className={styles.addToCalendarWrapper}>
			<Formik initialValues={initialValues} validationSchema={addToCalendarSchema} onSubmit={handleSubmit}>
				{({ handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<FlexBlock
							flexDirection={{
								[breakpoints.zero]: 'column',
								[breakpoints.phoneWide]: 'row',
							}}
							alignItems={{
								[breakpoints.zero]: 'unset',
								[breakpoints.phoneWide]: 'flex-end',
							}}
							className={styles.timeInputs}
						>
							<FormikDatePicker name="date" label="Choose date" />
							<FlexBlock columnGap="20px" alignItems="flex-end">
								<FormikTimeInput name="time" label="Time" />
								<FormikSelect name="duration" label="Duration" options={durationOptions} />
							</FlexBlock>
						</FlexBlock>
						<GlobalError />
						<Button type="submit" color="darkGreen" size="large" fullWidth>
							Add to Calendar
						</Button>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default AddToCalendarModal;
