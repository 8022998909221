import * as yup from 'yup';
import { EmissionTargetType } from '@/api/legacyEmissionTarget.ts';

const requiredMessage = 'This field is required';
const minValueMessage = 'Value must be at least 0.0001';

const validationSchemaEmissionTarget = {
	type: yup.object().shape({
		type: yup.string().required(requiredMessage),
	}),
	period: yup.object().shape({
		yearType: yup.string().required(requiredMessage),
		fiscalYearStart: yup.object().when('yearType', {
			is: (yearType: string) => yearType !== 'calendar',
			then: schema => schema.required(requiredMessage),
			otherwise: schema => schema.nullable(),
		}),
	}),
	baseline: yup.object().shape({
		lastYearCo2: yup.number().required(requiredMessage).min(0.0001, minValueMessage),
		// Validation for lastYearRevenue field only if selected type is 'intensity', otherwise there is no field
		lastYearRevenue: yup.number().when('type', (type: EmissionTargetType[], schema: yup.NumberSchema) => {
			return type && type[0] === 'intensity' ? schema.required(requiredMessage).min(0.0001, minValueMessage) : schema.nullable();
		}),
	}),
	target: yup.object().shape({
		expectedRevenue: yup.number().when('type', (type: EmissionTargetType[], schema) => {
			return type[0] === 'intensity' ? schema.required(requiredMessage).min(0.0001, minValueMessage) : schema.nullable();
		}),
		reductionPercentage: yup.number().required(requiredMessage),
	}),
	finish: {},
};

export default validationSchemaEmissionTarget;
