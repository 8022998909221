const routes = {
	dashboard: '/',
	myClimate: '/my-climate',
	climatePro: '/climate-pro',
	climateProSetting: '/climate-pro/setting',
	emissionReports: '/reports/emission',
	login: '/login',
	logout: '/user/logout',
	spotnanaRedirection: '/spotnana',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	employeeOnboard: '/onboard/employee/:token',
	ssoRegister: '/auth/organizations',
	noOrganization: '/auth/no-organization',
	authError: '/auth/error',
	activateAccount: '/user/activate/:token',
	thankYouForFeedback: '/thank-you-for-feedback',
	securitySettings: '/user/security',
};

export default routes;
