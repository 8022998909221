import axios from 'axios';
import ClimateProNotificationSettingsData = App.Data.ClimateProNotificationSettingsData;
import ListClimateProCertificatesResponse = App.Data.Response.ListClimateProCertificatesResponse;
import ListOrganizationCo2FactorFrameworksResponse = App.Data.Response.ListOrganizationCo2FactorFrameworksResponse;
import UpdateOrganizationCo2FactorFrameworksRequest = App.Data.Request.UpdateOrganizationCo2FactorFrameworksRequest;
import OrganizationEmissionTargetData = App.Data.OrganizationEmissionTargetData;
import UpdateOrganizationEmissionTargetRequest = App.Data.Request.UpdateOrganizationEmissionTargetRequest;
import ListAllocationEntitiesResponse = App.Data.Response.ListAllocationEntitiesResponse;
import ListAllocationResponsibleUsersResponse = App.Data.Response.ListAllocationResponsibleUsersResponse;
import ListAllocationResponse = App.Data.Response.ListAllocationResponse;
import UpdateEntityAllocationRequest = App.Data.Request.UpdateEntityAllocationRequest;
import ListClimateProCo2MetricsResponse = App.Data.Response.ListClimateProCo2MetricsResponse;
import ClimateProCo2MetricsRequest = App.Data.Request.ClimateProCo2MetricsRequest;
import ListClimateProFilterEntitiesResponse = App.Data.Response.ListClimateProFilterEntitiesResponse;
import queryString from 'query-string';

export const getNotificationSettings = async (): Promise<ClimateProNotificationSettingsData> => {
	const response = await axios.get('/climate-pro/notification-settings');
	return response.data;
};

export const updateNotificationSettings = async (
	data: ClimateProNotificationSettingsData,
): Promise<ClimateProNotificationSettingsData> => {
	const response = await axios.put('/climate-pro/notification-settings', data);
	return response.data;
};

export const getCertificates = async (): Promise<ListClimateProCertificatesResponse> => {
	const response = await axios.get('/climate-pro/certificates');
	return response.data;
};

export const getFactorFrameworks = async (): Promise<ListOrganizationCo2FactorFrameworksResponse> => {
	const response = await axios.get('/climate-pro/co2-factor-frameworks');
	return response.data;
};

export const updateFactorFrameworks = async (
	data: UpdateOrganizationCo2FactorFrameworksRequest,
): Promise<ListOrganizationCo2FactorFrameworksResponse> => {
	const response = await axios.put('/climate-pro/co2-factor-frameworks', data);
	return response.data;
};

export const getEmissionTargets = async (): Promise<OrganizationEmissionTargetData[]> => {
	const response = await axios.get('/climate-pro/emission-targets');
	return response.data.emissionTargets;
};

export const updateEmissionTargets = async (
	data: OrganizationEmissionTargetData,
): Promise<UpdateOrganizationEmissionTargetRequest> => {
	const response = await axios.put('/climate-pro/emission-targets', data);
	return response.data;
};

export const getAllocationEntities = async (): Promise<ListAllocationEntitiesResponse> => {
	const response = await axios.get('/climate-pro/emission-target-allocation/entities');
	return response.data;
};

export const getAllocationResponsiblePersons = async (): Promise<ListAllocationResponsibleUsersResponse> => {
	const response = await axios.get('/climate-pro/emission-target-allocation/responsible-users');
	return response.data;
};

export const getAllocationTables = async (): Promise<ListAllocationResponse> => {
	const response = await axios.get('/climate-pro/emission-target-allocation');
	return response.data;
};

export const updateAllocationTables = async (data: UpdateEntityAllocationRequest): Promise<void> => {
	const response = await axios.put('/climate-pro/emission-target-allocation', data);
	return response.data;
};

export const getMetrics = async (data: ClimateProCo2MetricsRequest): Promise<ListClimateProCo2MetricsResponse> => {
	const response = await axios.get(`/climate-pro/metrics?${queryString.stringify(data)}`);
	return response.data;
};

export const getEntitiesFilters = async (): Promise<ListClimateProFilterEntitiesResponse> => {
	const response = await axios.get('/climate-pro/filters/entities');
	return response.data;
};
