import styles from './PasswordInput.module.scss';
import React, { FocusEventHandler, useCallback, useState } from 'react';
import Eye from '@/assets/Icons/Eye-Fill.svg?react';
import EyeSlash from '@/assets/Icons/Eye-Slash-Fill.svg?react';
import classNames from 'classnames';

type Value = string | number | undefined;

export interface PasswordInputProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: string;
	onChange: (newValue: Value) => void;
	type?: 'text' | 'number' | 'password' | 'email' | 'tel';
	maxLength?: number;
	errorMessage?: string;
	touched?: boolean;
	min?: number;
	disabled?: boolean;
	onBlur?: FocusEventHandler | undefined;
	name?: string;
	ref?: React.Ref<HTMLInputElement>;
	color?: 'white' | 'gray';
	passwordPreviewable?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
	className,
	label,
	placeholder,
	value,
	onChange,
	type = 'password',
	maxLength,
	errorMessage,
	touched,
	min,
	disabled = false,
	onBlur,
	name,
	ref,
	color = 'white',
	passwordPreviewable = true,
}) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let newValue: Value = e.target.value;

			if (type === 'number') {
				newValue = parseFloat(newValue);

				if (Number.isNaN(newValue)) {
					newValue = undefined;
				}
			}

			onChange(newValue);
		},
		[onChange, type],
	);

	return (
		<div className={classNames(styles.wrapper, className)}>
			{!!label && <span className={styles.label}>{label}</span>}
			<div className={styles.inputWrapper}>
				<input
					ref={ref}
					name={name}
					type={passwordPreviewable && isPasswordVisible ? 'text' : type}
					className={classNames(styles.input, color === 'white' ? styles.white : styles.gray, passwordPreviewable && styles.isPreviewable)}
					placeholder={placeholder}
					value={value}
					onChange={handleChange}
					maxLength={maxLength}
					min={min}
					disabled={disabled}
					onBlur={onBlur}
				/>
				<button
					type="button"
					className={styles.toggleButton}
					onClick={() => {
						setIsPasswordVisible(!isPasswordVisible);
					}}
				>
					{passwordPreviewable && <>{isPasswordVisible ? <Eye /> : <EyeSlash />}</>}
				</button>
			</div>
			{!!errorMessage && touched && <span className={styles.errorMessage}>{errorMessage}</span>}
		</div>
	);
};

export default PasswordInput;
