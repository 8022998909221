import { create } from 'zustand';
import { STEPS, StepType } from '@/constants/emissionLegacyTarget.ts';
import { EmissionTargetPeriod, EmissionTargetType } from '@/api/legacyEmissionTarget.ts';

interface EmissionTargetTypes {
	activeStep: StepType;
	selectedType: EmissionTargetType;
	selectedPeriod: EmissionTargetPeriod | null;
	targetCo2: number | null;
	lastYearRevenue: number | null;
	calculatedEmissionTargetCo2: number | null;
	setCalculatedEmissionTargetCo2: (result: number) => void;
	changeStep: (step: StepType) => void;
	setType: (type: EmissionTargetType) => void;
	setPeriod: (period: EmissionTargetPeriod) => void;
	clickNext: () => void;
	clickBack: () => void;
	carbonIntensity: null | number;
	setCarbonIntensity: (carbonIntensity: number) => void;
	resetStore: () => void;
}

const initialState = {
	activeStep: STEPS[0],
	selectedType: 'absolute' as EmissionTargetType,
	selectedPeriod: null,
	targetCo2: null,
	lastYearRevenue: null,
	carbonIntensity: null,
	calculatedEmissionTargetCo2: 0,
};

export const useLegacyEmissionTargetStore = create<EmissionTargetTypes>(set => ({
	...initialState,
	setCarbonIntensity: carbonIntensity =>
		set({
			carbonIntensity: carbonIntensity,
		}),
	setCalculatedEmissionTargetCo2: result =>
		set({
			calculatedEmissionTargetCo2: result,
		}),
	changeStep: step =>
		set({
			activeStep: step,
		}),
	setType: type =>
		set({
			selectedType: type,
			activeStep: 'period',
		}),
	setPeriod: period =>
		set({
			selectedPeriod: period,
			activeStep: period === 'fiscal' ? 'fiscalDate' : 'baseline',
		}),
	clickNext: () =>
		set(state => {
			if (state.activeStep !== 'target') {
				return { activeStep: STEPS[STEPS.indexOf(state.activeStep) + 1] };
			}
			return state;
		}),
	clickBack: () =>
		set(state => {
			const stepsBack = state.activeStep === 'baseline' && state.selectedPeriod === 'calendar' ? 2 : 1;
			const newIndex = STEPS.indexOf(state.activeStep) - stepsBack;
			if (newIndex >= 0) {
				return { activeStep: STEPS[newIndex] };
			}
			return state;
		}),
	resetStore: () => set(initialState),
}));
