import React, { useEffect, useState } from 'react';
import EarthHomeWorldIcon from '@/assets/Icons/Earth-Home-World-2.svg?react';
import classNames from 'classnames';
import styles from './LanguageMenu.module.scss';
import { Popover } from 'react-tiny-popover';
import { map, pick } from 'lodash';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import languages from '@/constants/languages.ts';
import { updateUser } from '@/api/user.ts';
import usePersistentPreferencesStore from '@/store/usePersistentPreferencesStore.ts';
import { toast } from 'react-toastify';

interface LanguageMenuProps {
	showDropdown?: boolean;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ showDropdown }) => {
	const { setWeGlotLanguageCode, weGlotLanguageCode: selectedLanguageCode, loggedIn } = useWhoamiStore(state => state);
	const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);
	const [prevShowDropdown, setPrevShowDropdown] = useState(false);
	const { isLanguageNotificationSeen } = usePersistentPreferencesStore();

	// Accessing the `Weglot` object from the global window, loaded by WeglotProvider.tsx
	const Weglot = window?.Weglot;

	// State to store the available languages (for rendering dropdown)
	const [availableLanguages, setAvailableLanguages] = useState({});

	// Show language menu if change language info is seen and closed
	useEffect(() => {
		if (!prevShowDropdown && showDropdown) {
			if (!isPopoverShown && isLanguageNotificationSeen && !loggedIn) {
				setIsPopoverShown(true);
			}
		}
		setPrevShowDropdown(showDropdown || false);
	}, [showDropdown, prevShowDropdown, isPopoverShown, loggedIn, isLanguageNotificationSeen]);

	useEffect(() => {
		// If Weglot is not yet loaded, skip
		if (!Weglot?.options?.languages) {
			return;
		}

		// Get all supported Weglot language codes
		const supportedWeGlotCodes = Weglot?.options?.languages.filter(({ enabled = false }) => enabled).map(({ language_to }) => language_to);

		// Get all language key-values pairs (including English)
		// Format: [{languageCode: languageName}]
		setAvailableLanguages(pick(languages, ['en', ...supportedWeGlotCodes]));
	}, [Weglot?.options?.languages]);

	const handleLanguageChange = async (weGlotLanguageCode: string) => {
		// Hide the dropdown
		setIsPopoverShown(false);

		// Set the selected language code in the store
		setWeGlotLanguageCode(weGlotLanguageCode);

		if (loggedIn) {
			// If the user is logged-in, send a request to update his language code
			try {
				await updateUser({
					weGlotLanguageCode,
				});
			} catch {
				toast.error('Something went wrong. Please try again later.');
			}
		}
	};

	return (
		<>
			<Popover
				isOpen={isPopoverShown}
				onClickOutside={() => setIsPopoverShown(false)}
				positions={['bottom', 'right']}
				align="end"
				padding={20}
				transformMode="relative"
				reposition={false}
				content={
					<div className={styles.popover}>
						<p className={styles.langTitle}>Language</p>
						{map(availableLanguages, (langName, langCode) => (
							<div
								className={classNames(styles.popupItem, selectedLanguageCode === langCode && styles.active)}
								key={langCode}
								onClick={() => handleLanguageChange(langCode)}
							>
								{langName}
							</div>
						))}

						<div className={styles.additionalInfo}>
							*Translations have been generated using AI, and may show occasional discrepancies with original content
						</div>
					</div>
				}
			>
				<div
					className={styles.iconWrapper}
					onClick={() => setIsPopoverShown(!isPopoverShown)}
					key={selectedLanguageCode} // Render it when `weGlotLanguageCode` changes
				>
					<EarthHomeWorldIcon />
					<span className={styles.selectedLang}>{selectedLanguageCode}</span>
				</div>
			</Popover>
		</>
	);
};

export default LanguageMenu;
