import axios from 'axios';
import queryString from 'query-string';

type BreakdownMe = {
	itineraryName: string;
	date: string;
	co2eTotal: number;
};

type BreakdownDepartment = {
	userFullName: string;
	department: string;
	co2eTotal: number;
};

type BreakdownLegalEntity = {
	department: string;
	userCount: number;
	co2eTotal: number;
};

type BreakdownCompany = {
	legalEntity: string;
	userCount: number;
	co2eTotal: number;
};

type ActualForecastTarget = {
	me: number;
	department: number;
	legalEntity: number;
	company: number;
};

type SavingsTrips = {
	savings: number;
	tripsCount: number;
};

export interface AnalyticsData {
	actual?: number;
	directFlights?: number;
	economyClass?: number;
	bundleTrips?: number;
	forecast?: number;
	label: string;
}

export type Segments = {
	label: string;
	car?: number;
	flight?: number;
	hotel?: number;
	rail?: number;
};

type ForecastOptions = {
	economyClass: {
		me: SavingsTrips;
		department: SavingsTrips;
		legalEntity: SavingsTrips;
		company: SavingsTrips;
	};
	bundleTrips: {
		me: SavingsTrips;
		department: SavingsTrips;
		legalEntity: SavingsTrips;
		company: SavingsTrips;
	};
	directFlights: {
		me: SavingsTrips;
		department: SavingsTrips;
		legalEntity: SavingsTrips;
		company: SavingsTrips;
	};
};

export type Analytics = {
	me: AnalyticsData[];
	department: AnalyticsData[];
	legalEntity: AnalyticsData[];
	company: AnalyticsData[];
};

export interface DashboardAnalyticsData {
	segments: {
		me: Segments[];
		department: Segments[];
		legalEntity: Segments[];
		company: Segments[];
	};
	breakdown: {
		me: BreakdownMe[];
		department: BreakdownDepartment[];
		legalEntity: BreakdownLegalEntity[];
		company: BreakdownCompany[];
	};
	stats: {
		actual: ActualForecastTarget;
		forecast: ActualForecastTarget;
		target: Pick<ActualForecastTarget, 'company'>;
	};
	options: ForecastOptions;
	analytics: Analytics;
}

export type AnalyticsPeriod = 'current-year' | 'last-12-months' | 'last-6-months' | 'last-3-months';
export const initialAnalyticsPeriod = 'current-year';

export const getDashboardAnalyticsLegacy = async (
	period: AnalyticsPeriod = initialAnalyticsPeriod,
): Promise<DashboardAnalyticsData | null> => {
	const response = await axios.get(`/dashboard/analytics?period=${period}`);
	return await response?.data;
};

export const getDashboardAnalytics = async (
	data: {
		period: AnalyticsPeriod;
		sourceModelClass: string;
		sourceModelId: number;
	} = { period: initialAnalyticsPeriod, sourceModelClass: '', sourceModelId: 0 },
): Promise<DashboardAnalyticsData | null> => {
	const response = await axios.get(`/dashboard/analytics?${queryString.stringify(data)}`);
	return await response?.data;
};
