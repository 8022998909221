import {
	Button,
	Calendar,
	CalendarCell,
	CalendarGrid,
	DateInput,
	DatePicker as ReactDatePicker,
	DatePickerProps,
	DateSegment,
	DateValue,
	Dialog,
	Group,
	Heading,
	Label,
	Popover,
} from 'react-aria-components';
import styles from './DatePicker.module.scss';
import CalendarIcon from '@/assets/Icons/Calendar-Schedule-1.svg?react';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import ArrowLeft from '@/assets/Icons/Arrow-Left.svg?react';
import classNames from 'classnames';

export interface CustomDatePickerProps<T extends DateValue> extends DatePickerProps<T> {
	label?: string;
	disabled?: boolean;
	className?: string;
	value?: T | null | undefined;
	errorMessage?: string;
	touched?: boolean;
}

const DatePicker = <T extends DateValue>({
	label,
	name,
	onChange,
	disabled,
	value,
	onBlur,
	errorMessage,
	touched,
	...props
}: CustomDatePickerProps<T>) => {
	return (
		<ReactDatePicker
			className={styles.datePickerWrapper}
			name={name}
			onBlur={onBlur}
			isDisabled={disabled}
			onChange={onChange}
			value={value}
			{...props}
		>
			<Label className={styles.label}>{label}</Label>
			<Group className={styles.group}>
				<DateInput className={styles.dateInput}>{segment => <DateSegment segment={segment} />}</DateInput>
				<Button className={styles.calendarButton}>
					<CalendarIcon />
				</Button>
			</Group>
			{touched && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
			<Popover className={styles.popover}>
				<Dialog className={styles.dialog}>
					<Calendar className={styles.calendar}>
						<header>
							<Heading />
							<div className={styles.buttons}>
								<Button slot="previous">
									<ArrowLeft />
								</Button>
								<Button slot="next">
									<ArrowRight />
								</Button>
							</div>
						</header>
						<CalendarGrid className={styles.calendarGrid}>
							{date => (
								<CalendarCell
									date={date}
									className={({ isOutsideMonth }) => classNames(styles.calendarCell, { [styles.fade]: isOutsideMonth })}
								/>
							)}
						</CalendarGrid>
					</Calendar>
				</Dialog>
			</Popover>
		</ReactDatePicker>
	);
};

export default DatePicker;
