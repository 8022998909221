import React, { useEffect } from 'react';
import { Formik, FormikHelpers, FormikProvider } from 'formik';
import styles from './FactorFramework.module.scss';
import Card from '@/components/ui/Card/Card.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Image from '@/components/ui/Image/Image.tsx';
import FactorFrameworkImage from '@/assets/Images/Factor-Framework.png';
import OpenInNewTabIcon from '@/assets/Icons/Open-In-New-Tab.svg?react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Flyout, { FlyoutCloseButton } from '@/components/ui/Flyout/Flyout.tsx';
import HistoryIcon from '@/assets/Icons/History.svg?react';
import DoneCheckmarkIcon from '@/assets/Icons/Done-Checkmark.svg?react';
import moment from 'moment';
import {
	carFactorFrameworkData,
	flightFactorFrameworkData,
	hotelFactorFrameworkData,
	railFactorFrameworkData,
} from '@/constants/climatePro.ts';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getFactorFrameworks, updateFactorFrameworks } from '@/api/climatePro.ts';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import UnsavedChangesToast from '@/pages/ClimateProDashboard/UnsavedChangesToast/UnsavedChangesToast.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import FormikCheckboxToggle from '@/components/Formik/FormikCheckboxToggle.tsx';
import { toast } from 'react-toastify';
import OrganizationCo2FactorFrameworkData = App.Data.OrganizationCo2FactorFrameworkData;
import Co2CarFactorFramework = App.Enums.Co2CarFactorFramework;
import Co2FlightFactorFramework = App.Enums.Co2FlightFactorFramework;
import Co2HotelFactorFramework = App.Enums.Co2HotelFactorFramework;
import Co2RailFactorFramework = App.Enums.Co2RailFactorFramework;
import { useModalStore } from '@/store/useModalStore.ts';
import WarningModal from '@/pages/ClimateProDashboard/FactorFramework/WarningModal.tsx';

interface HistoryFlyoutProps {
	name: string;
	historyData: OrganizationCo2FactorFrameworkData[];
}

const HistoryFlyout: React.FC<HistoryFlyoutProps> = ({ name, historyData }) => (
	<div className={styles.flyoutWrapper}>
		<Flyout
			id={name}
			button={
				<button className={styles.iconWrapper}>
					<HistoryIcon />
				</button>
			}
			className={styles.flyout}
		>
			<FlexBlock justifyContent="space-between" alignItems="center" className={styles.flyoutHeader}>
				<h3>History of changes for {name}</h3>
				<FlyoutCloseButton />
			</FlexBlock>
			{historyData.map((data, index) => (
				<div key={index} className={styles.co2HistoryBox}>
					<FlexBlock justifyContent="space-between" alignItems="center" className={styles.historyInfo}>
						<p className={styles.time}>{moment(data.createdAt).format('MMM D, YYYY, h:mm:ss A [GMT]Z')}</p>
						{data.current && (
							<FlexBlock
								justifyContent="space-between"
								alignItems="center"
								columnGap="4px"
								className={styles.successMarker}
							>
								<DoneCheckmarkIcon /> Current
							</FlexBlock>
						)}
					</FlexBlock>
					{data.framework}
				</div>
			))}
		</Flyout>
	</div>
);

const FactorFramework: React.FC = () => {
	const { data, isSuccess, isLoading, refetch, isError } = useQuery({
		queryKey: [queryKeys.factorFramework],
		queryFn: getFactorFrameworks,
	});
	const { openModal, activeModal } = useModalStore(state => state);

	const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
		try {
			await updateFactorFrameworks(values);
			await refetch();
			toast.success('Successfully saved changes.');
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	const getAvailableFrameworks = (
		frameworks: string[],
		currentValue:
			| Co2CarFactorFramework
			| Co2FlightFactorFramework
			| Co2HotelFactorFramework
			| Co2RailFactorFramework,
	) => {
		return frameworks
			.filter(framework => framework !== 'GOODWINGS' || framework === currentValue)
			.map(framework => ({ label: framework, value: framework }));
	};

	useEffect(() => {
		isError && toast.error('Something went wrong. Please try again later.');
	}, [isError]);

	return (
		<FlexBlock flexDirection="column" rowGap="40px" className={styles.factorFrameworkWrapper}>
			<h1>CO2 factor framework</h1>

			<Card className={styles.card}>
				<h3 className={styles.cardTitle}>Choosing the right methodology for your company</h3>
				<FlexBlock columnGap="52px">
					<div>
						<p className={styles.cardText}>
							There are many acknowledged methodologies for calculating emissions. They are all accurate,
							but differ a lot since they take various factors into account differently. Example, some
							include Radiative Forcing Index when calculating emissions from air travel, while other do
							not.
						</p>
						<p className={styles.cardText}>
							Your choice of emissions factor source can have a big impact on your calculated travel
							emissions as shown on this graph.
						</p>
						<Link to={'#'} className={styles.readMore}>
							Read more about the methodologies and our recommendations for your company
							<OpenInNewTabIcon />
						</Link>
					</div>
					{/* TODO - add fallbackImg*/}
					<Image src={FactorFrameworkImage} fallbackSrc={''} />
				</FlexBlock>
			</Card>

			{isLoading && (
				<div className={styles.spinnerWrapper}>
					<Spinner absolute />
				</div>
			)}

			{isSuccess && data && (
				<Formik
					initialValues={{
						flight: data.flight.find(flight => flight.current)?.framework,
						hotel: data.hotel.find(flight => flight.current)?.framework,
						rail: data.rail.find(flight => flight.current)?.framework,
						car: data.car.find(flight => flight.current)?.framework,
					}}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{formik => (
						<FormikProvider value={formik}>
							<FlexBlock flexDirection="column" rowGap="40px">
								<h2>Flights</h2>
								<Card className={classNames(styles.card, styles.flights)}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											name="flight"
											options={getAvailableFrameworks(
												flightFactorFrameworkData,
												formik.values.flight!,
											)}
											className={styles.select}
										/>
										{data && <HistoryFlyout name="flight" historyData={data.flight} />}
									</FlexBlock>
									<FlexBlock columnGap="40px" className={styles.checkboxes}>
										<FlexBlock columnGap="15px">
											<p>RFI</p> <FormikCheckboxToggle name="rfi" />
										</FlexBlock>
										<FlexBlock columnGap="15px">
											<p>WTT</p>
											<FormikCheckboxToggle name="wtt" />
										</FlexBlock>
									</FlexBlock>
								</Card>

								<h2>Accommodation</h2>
								<Card className={classNames(styles.card, styles.flights)}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											name="hotel"
											options={getAvailableFrameworks(
												hotelFactorFrameworkData,
												formik.values.hotel!,
											)}
											className={styles.select}
										/>
										{data && <HistoryFlyout name="hotel" historyData={data?.hotel} />}
									</FlexBlock>
								</Card>

								<h2>Rail</h2>
								<Card className={classNames(styles.card, styles.flights)}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											name="rail"
											options={getAvailableFrameworks(
												railFactorFrameworkData,
												formik.values.rail!,
											)}
											className={styles.select}
										/>
										{data && <HistoryFlyout name="rail" historyData={data?.rail} />}
									</FlexBlock>
								</Card>

								<h2>Rental cars</h2>
								<Card className={classNames(styles.card, styles.flights)}>
									<p>Changes are allowed only once per year</p>
									<FlexBlock columnGap="20px">
										<FormikSelect
											name="car"
											options={getAvailableFrameworks(carFactorFrameworkData, formik.values.car!)}
											position="top"
											className={styles.select}
										/>
										{data && <HistoryFlyout name="car" historyData={data?.car} />}
									</FlexBlock>
								</Card>
							</FlexBlock>
							{formik.dirty && (
								<UnsavedChangesToast
									onCancel={() => formik.resetForm()}
									onSubmit={() => {
										openModal('warningFactorFrameworkModal', () =>
											handleSubmit(formik.values, formik),
										);
									}}
								/>
							)}
						</FormikProvider>
					)}
				</Formik>
			)}
			{activeModal === 'warningFactorFrameworkModal' && <WarningModal />}
		</FlexBlock>
	);
};

export default FactorFramework;
