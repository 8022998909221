import { externalLinks } from '@/constants/externalLinks.ts';
import routes from '@/constants/routes.ts';

export const companyAdminLinks = [
	{ to: routes.dashboard, label: 'Climate' },
	{ to: routes.myClimate, label: 'My Climate' },
	{ to: routes.climateProSetting, label: 'Climate Pro', match: routes.climatePro },
	{ to: externalLinks.spotnana.myProfile, label: 'Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
	{ to: externalLinks.spotnana.book, label: 'Book' },
	{ to: externalLinks.spotnana.myTrips, label: 'My trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'My Approvals' },
	{ to: externalLinks.spotnana.companyTrips, label: 'Company Trips' },
	{ to: externalLinks.spotnana.companyApprovals, label: 'Company Approvals' },
	{ to: externalLinks.spotnana.support, label: 'Support' },
	{ to: externalLinks.spotnana.myReports, label: 'My reports' },
	{ to: externalLinks.spotnana.companyReports, label: 'Company Reports' },
	{ to: externalLinks.spotnana.travellersSafety, label: 'Safety' },
	{ to: externalLinks.spotnana.admin, label: 'Company' },
	{ to: externalLinks.spotnana.usersSettings, label: 'Users' },
	{ to: externalLinks.spotnana.policies, label: 'Policies' },
];

export const companyTravelArrangerLinks = [
	{ to: routes.dashboard, label: 'Climate' },
	{ to: routes.myClimate, label: 'My Climate' },
	{ to: externalLinks.spotnana.myProfile, label: 'Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
	{ to: externalLinks.spotnana.book, label: 'Book' },
	{ to: externalLinks.spotnana.myTrips, label: 'My trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'My Approvals' },
	{ to: externalLinks.spotnana.companyTrips, label: 'Company Trips' },
	{ to: externalLinks.spotnana.support, label: 'Support' },
	{ to: externalLinks.spotnana.myReports, label: 'My reports' },
];

export const travelArrangerLinks = [
	{ to: routes.dashboard, label: 'Climate' },
	{ to: routes.myClimate, label: 'My Climate' },
	{ to: externalLinks.spotnana.myProfile, label: 'Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
	{ to: externalLinks.spotnana.book, label: 'Book' },
	{ to: externalLinks.spotnana.myTrips, label: 'My trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'My Approvals' },
	{ to: externalLinks.spotnana.support, label: 'Support' },
	{ to: externalLinks.spotnana.myReports, label: 'My reports' },
];

export const userLinks = [
	{ to: routes.dashboard, label: 'Climate' },
	{ to: routes.myClimate, label: 'My Climate' },
	{ to: externalLinks.spotnana.myProfile, label: 'Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
	{ to: externalLinks.spotnana.book, label: 'Book' },
	{ to: externalLinks.spotnana.myTrips, label: 'My trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'My Approvals' },
	{ to: externalLinks.spotnana.support, label: 'Support' },
	{ to: externalLinks.spotnana.myReports, label: 'My reports' },
];

export const tmcAdminLinks = [
	{ to: routes.dashboard, label: 'Climate' },
	{ to: routes.myClimate, label: 'My Climate' },
	{ to: routes.climateProSetting, label: 'Climate Pro', match: routes.climatePro },
	{ to: externalLinks.spotnana.myProfile, label: 'Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
	{ to: externalLinks.spotnana.book, label: 'Book' },
	{ to: externalLinks.spotnana.myTrips, label: 'My trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'My Approvals' },
	{ to: externalLinks.spotnana.companyTrips, label: 'Company Trips' },
	{ to: externalLinks.spotnana.companyApprovals, label: 'Company Approvals' },
	{ to: externalLinks.spotnana.events, label: 'Events' },
	{ to: externalLinks.spotnana.support, label: 'Support' },
	{ to: externalLinks.spotnana.agent, label: 'Agent' },
	{ to: externalLinks.spotnana.travellersSafety, label: 'Travellers' },
	{ to: externalLinks.spotnana.myReports, label: 'My reports' },
	{ to: externalLinks.spotnana.companyReports, label: 'Company reports' },
	{ to: externalLinks.spotnana.admin, label: 'Company' },
	{ to: externalLinks.spotnana.usersSettings, label: 'Users' },
	{ to: externalLinks.spotnana.policies, label: 'Policies' },
];

export const tmcAgentLinks = [
	{ to: routes.dashboard, label: 'Climate' },
	{ to: routes.myClimate, label: 'My Climate' },
	{ to: externalLinks.spotnana.myProfile, label: 'Profile' },
	{ to: routes.securitySettings, label: 'Security Settings' },
	{ to: externalLinks.spotnana.book, label: 'Book' },
	{ to: externalLinks.spotnana.myTrips, label: 'My trips' },
	{ to: externalLinks.spotnana.myApprovals, label: 'My Approvals' },
	{ to: externalLinks.spotnana.companyTrips, label: 'Company Trips' },
	{ to: externalLinks.spotnana.companyApprovals, label: 'Company Approvals' },
	{ to: externalLinks.spotnana.support, label: 'Support' },
	{ to: externalLinks.spotnana.agent, label: 'Agent' },
	{ to: externalLinks.spotnana.travellersSafety, label: 'Travellers' },
	{ to: externalLinks.spotnana.myReports, label: 'My reports' },
	{ to: externalLinks.spotnana.companyReports, label: 'Company reports' },
	{ to: externalLinks.spotnana.admin, label: 'Company' },
	{ to: externalLinks.spotnana.usersSettings, label: 'Users' },
	{ to: externalLinks.spotnana.policies, label: 'Policies' },
];
