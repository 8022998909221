import { toast } from 'react-toastify';
import Container from '@/components/ui/Container/Container.tsx';
import styles from './ChangePassword.module.scss';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import { Form, Formik, FormikHelpers } from 'formik';
import FormikPasswordInput from '@/components/Formik/FormikPasswordInput.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import { changePasswordSchema } from '@/validations/user.ts';
import { changePassword } from '@/api/user.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import GlobalError, { GlobalErrors } from '@/components/FormikErrors/GlobalError.tsx';

interface ChangePasswordValues extends GlobalErrors {
	oldPassword: string;
	password: string;
	confirmPassword: string;
}

const ChangePassword: React.FC = () => {
	const hasPassword = useWhoamiStore(state => state.user?.hasPassword);

	const handleSubmit = async (
		values: ChangePasswordValues,
		formikHelpers: FormikHelpers<ChangePasswordValues>,
	): Promise<void> => {
		const { resetForm } = formikHelpers;

		try {
			await changePassword(values);
			resetForm();
			toast.success('Your changes were saved!');
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
			toast.error('Failed to save changes. Please try again.');
		}
	};

	return (
		<Container>
			<div className={styles.changePasswordWrapper}>
				<h3>{hasPassword ? 'Change password' : 'Create password'}</h3>
				<Formik
					initialValues={{
						oldPassword: '',
						password: '',
						confirmPassword: '',
					}}
					validationSchema={changePasswordSchema(hasPassword || false)}
					onSubmit={handleSubmit}
				>
					{({ isSubmitting }) => (
						<Form className={styles.form}>
							<GlobalError />
							{hasPassword && (
								<FormikPasswordInput
									name="oldPassword"
									label="Old Password*"
									type="password"
									className={styles.formikInput}
								/>
							)}
							<FormikPasswordInput
								name="password"
								label="New Password*"
								type="password"
								className={styles.formikInput}
							/>
							<FormikPasswordInput
								name="confirmPassword"
								label="Confirm New Password*"
								type="password"
								className={styles.formikInput}
							/>
							<Button
								type="submit"
								size="large"
								color="green"
								disabled={isSubmitting}
								loading={isSubmitting}
								className={styles.submitButton}
							>
								Save new password
							</Button>
						</Form>
					)}
				</Formik>
			</div>
		</Container>
	);
};

export default ChangePassword;
