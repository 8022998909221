import React from 'react';
import { useField } from 'formik';
import CheckboxToggle, { CheckboxToggleProps } from '@/components/ui/CheckboxToggle/CheckboxToggle.tsx';

interface FormikCheckboxToggleProps
	extends Omit<CheckboxToggleProps, 'checked' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikCheckboxToggle: React.FC<FormikCheckboxToggleProps> = ({ name, ...props }) => {
	const [field, _, helpers] = useField(name);
	const { value } = field;
	const { setValue, setTouched } = helpers;

	const handleChange = () => {
		setValue(!value);
		setTouched(true);
	};

	return (
		<CheckboxToggle
			{...props}
			checked={value}
			onChange={handleChange}
			name={name}
			className={props.className}
			style={props.style}
		/>
	);
};

export default FormikCheckboxToggle;
