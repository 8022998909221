import React from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import CopyIcon from '@/assets/Icons/Copy-Clipboard.svg?react';
import styles from './ShareModal.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import { toast } from 'react-toastify';

const ShareModal: React.FC = () => {
	const downloadUrl: string = useModalStore(state => state.data?.downloadUrl);

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(downloadUrl)
			.then(() => {
				toast.success('Successfully copied');
			})
			.catch(() => {
				toast.error('URL can not be copied');
			});
	};

	return (
		<>
			<Modal name="certificateShareModal" className={styles.shareModalWrapper}>
				<p className={styles.title}>Here's your unique URL which you can share with others</p>
				<FlexBlock columnGap="10px" alignItems="center">
					<div className={styles.url}>
						<p>{downloadUrl}</p>
					</div>
					<button onClick={copyToClipboard} className={styles.copyBttn}>
						<FlexBlock alignItems="center" columnGap="6px">
							<CopyIcon /> Copy
						</FlexBlock>
					</button>
				</FlexBlock>
			</Modal>
		</>
	);
};

export default ShareModal;
