import { round } from 'lodash';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import OrganizationEmissionTargetData = App.Data.OrganizationEmissionTargetData;
import { Option } from '@/components/ui/Select/Select.tsx';

export const STEPS = ['type', 'period', 'baseline', 'target'] as const;

export type StepType = (typeof STEPS)[number];
type StepsProps = { text: string; steps: StepType[] }[];
export const ShownHeaderSteps: StepsProps = [
	{
		text: 'Target type',
		steps: ['type'],
	},
	{
		text: 'Time Period',
		steps: ['period'],
	},
	{
		text: 'Decide a baseline',
		steps: ['baseline'],
	},
	{
		text: 'Your emissions reduction target',
		steps: ['target'],
	},
];

// Target type options
export const targetTypeOptions = [
	{
		label: 'Absolute',
		value: 'absolute',
	},
	{
		label: 'Intensity',
		value: 'intensity',
	},
];

// Time period options
export const timeTypeOptions = [
	{
		label: 'Fiscal',
		value: 'fiscal',
	},
	{
		label: 'Calendar',
		value: 'calendar',
	},
];

// Initial values for formik
export const defaultInitialValues: EmissionTargetValues = {
	type: 'intensity',
	yearType: 'fiscal',
	reductionPercentage: 5,
};

// Transform data to get fiscalStartYear object needed for CalendarPicker in fiscalYear step
export const transformEmissionTargetData = (data: OrganizationEmissionTargetData[]) => {
	return data.map(item => {
		if (item.fiscalStartDay && item.fiscalStartMonth) {
			return {
				...item,
				fiscalStartYear: {
					day: item.fiscalStartDay,
					month: item.fiscalStartMonth,
				},
			};
		}
		return item;
	});
};

export const calculateEmissionTarget = (
	type?: OrganizationEmissionTargetData['type'],
	targetCo2?: number | null,
	reductionPercentage?: number | null,
	getCarbonEmitCo2?: number | null,
) => {
	if (type === 'absolute' && targetCo2 && reductionPercentage) {
		return round(targetCo2 * ((100 - reductionPercentage) / 100), 2);
	}

	if (type === 'intensity' && getCarbonEmitCo2 && reductionPercentage) {
		return round(getCarbonEmitCo2 * ((100 - reductionPercentage) / 100), 2);
	}
	return 0;
};

export const getCarbonEmitCo2 = (carbonIntensity: number | null, expectedRevenue: number | null) => {
	if (!carbonIntensity || !expectedRevenue || carbonIntensity === 0) {
		return 0;
	}

	return round(expectedRevenue * carbonIntensity, 2);
};

export const getCarbonIntensity = (targetCo2: number | null, lastYearRevenue: number | null) => {
	if (!targetCo2 || !lastYearRevenue) {
		return 0;
	}

	return round(targetCo2 / lastYearRevenue, 2);
};

export const selectOptions: Option[] = Array.from({ length: 20 }, (_, index) => {
	const value = index * 5;
	return {
		label: `${value}% reduction`,
		value,
	};
});
