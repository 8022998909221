import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsersOrganization, registerUser, RegisterUserRequest, whoami } from '@/api/auth';
import { useParams } from 'react-router';
import styles from './EmployeeOnboard.module.scss';
import MainLayout from '@/layouts/MainLayout/MainLayout';
import Container from '@/components/ui/Container/Container';
import { Form, Formik, FormikHelpers } from 'formik';
import FormikInput from '@/components/Formik/FormikInput';
import FormikSelect from '@/components/Formik/FormikSelect';
import Button from '@/components/ui/Button/Button';
import { countries } from '@/constants/countries';
import { onboardEmployeeSchema } from '@/validations/auth.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import FormikPhoneInput from '@/components/Formik/FormikPhoneInput.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import queryKeys from '@/constants/queryKeys.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import FormikPasswordInput from '@/components/Formik/FormikPasswordInput.tsx';

const countriesOptions = countries.map(value => ({ label: value.name, value: value.countryCode }));

const EmployeeOnboard: React.FC = () => {
	const setWhoami = useWhoamiStore(state => state.setWhoami);
	const { token } = useParams<{ token: string }>();
	const { data, isError, isLoading } = useQuery({
		queryKey: [queryKeys.getEmployeeOnboardOrganizations, token],
		queryFn: () => getUsersOrganization(token || ''),
		enabled: !!token,
	});

	const [initialValues, setInitialValues] = useState<RegisterUserRequest>({
		user: {
			firstName: '',
			middleName: '',
			lastName: '',
			email: '',
			password: '',
			confirmPassword: '',
			phone: '',
			addressLine1: '',
			city: '',
			postalCode: '',
			countryCode: '',
			stateProvinceCode: '',
		},
		legalEntityId: undefined,
	});

	useEffect(() => {
		if (data) {
			const legalEntityId = data.legalEntities?.length === 1 ? data.legalEntities[0].id : undefined;
			setInitialValues(prev => ({
				...prev,
				user: {
					...prev.user,
					addressLine1: data.addressLine1 || '',
					postalCode: data.postalCode || '',
					countryCode: data.countryCode || '',
				},
				legalEntityId,
			}));
		}
	}, [data]);

	const legalEntitiesOptions =
		data?.legalEntities?.map(entity => ({
			label: entity.name,
			value: entity.id,
		})) || [];

	const getStatesOptions = (countryCode: string) => {
		const country = countries.find(country => country.countryCode === countryCode);
		if (country && country.states) {
			return Object.entries(country.states).map(([key, value]) => ({
				value: key,
				label: value,
			}));
		}
		return [];
	};

	const handleSubmit = async (values: RegisterUserRequest, formikHelpers: FormikHelpers<RegisterUserRequest>) => {
		try {
			const changedValues = { ...values, onboardToken: token };
			await registerUser(changedValues);
			setWhoami(await whoami());
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	if (isLoading) {
		return (
			<MainLayout>
				<Spinner />
			</MainLayout>
		);
	}

	if (isError) {
		return (
			<MainLayout>
				<Container>
					<div className={styles.employeeOnboardWrapper}>
						<h1>Not Found</h1>
						<p>
							We're sorry, but the page you are looking for could not be found. Please check the information provided and try again. If
							you continue to experience issues, kindly contact our support team for assistance. We apologize for any inconvenience
							caused.
						</p>
					</div>
				</Container>
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<Container>
				<div className={styles.employeeOnboardWrapper}>
					<h1>{data?.name} & Goodwings</h1>
					<h3>Create your free profile</h3>
					<p>
						We've partnered with Goodwings, the leading travel management system for responsible business travel. It's easy to use -
						simply book your travel with Goodwings and they'll calculate your full trip emissions - everything from your flights, hotels,
						trains, taxis and meals, and reduce them for free. How do they pay for it? They don't profit from your bookings like other
						travel management systems. Instead, they channel the commissions they earn from your bookings directly into green technology
						investments like biofuel and reforestation projects. Cool, right?
					</p>
					<h3>Let's get started </h3>
					<p>
						Simply fill out your details below to create your free profile, and we'll send you an email so you can start booking right
						away!
					</p>
					<Formik
						initialValues={initialValues}
						validationSchema={onboardEmployeeSchema(data?.legalEntities || [])}
						onSubmit={handleSubmit}
						enableReinitialize={true}
					>
						{({ values, isSubmitting }) => (
							<Form>
								<GlobalError />
								<FormikInput name="user.firstName" label="First name*" className={styles.formikInput} />
								<FormikInput name="user.middleName" label="Middle name" className={styles.formikInput} />
								<FormikInput name="user.lastName" label="Last name*" className={styles.formikInput} />
								<FormikInput name="user.email" type="email" label="Email*" className={styles.formikInput} />
								<FormikPasswordInput name="user.password" type="password" label="Password*" className={styles.formikInput} />
								<FormikPasswordInput
									name="user.confirmPassword"
									type="password"
									label="Confirm Password*"
									className={styles.formikInput}
								/>
								<FormikPhoneInput
									name="user.phone"
									defaultValue={values.user.countryCode}
									label="Phone*"
									className={styles.formikInput}
								/>
								{data?.legalEntities && data.legalEntities.length > 1 && (
									<FormikSelect
										name="legalEntityId"
										options={legalEntitiesOptions}
										label="Legal entity*"
										placeholder="Choose legal entity"
										className={styles.formikInput}
									/>
								)}
								<div className={styles.address}>
									<FormikInput name="user.addressLine1" label="Address" className={styles.formikInput} />
									<FormikInput name="user.city" label="City" className={styles.formikInput} />
									<FormikInput name="user.postalCode" label="Postcode" className={styles.formikInput} />
								</div>
								<div className={styles.country}>
									<FormikSelect
										name="user.countryCode"
										label="Country*"
										options={countriesOptions}
										className={styles.formikInput}
									/>
									<FormikSelect
										name="user.stateProvinceCode"
										label="State"
										options={values.user.countryCode ? getStatesOptions(values.user.countryCode) : []}
										disabled={
											!values.user.countryCode ||
											!countries.find(country => country.countryCode === values.user.countryCode)?.states
										}
										className={styles.formikInput}
									/>
								</div>

								<Button type="submit" color="green" size="large" disabled={isSubmitting} className={styles.submitButton}>
									Join {data?.name}
								</Button>
							</Form>
						)}
					</Formik>
				</div>
			</Container>
		</MainLayout>
	);
};

export default EmployeeOnboard;
