import React, { useEffect, useState } from 'react';
import styles from './UpcomingTrips.module.scss';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Image from '@/components/ui/Image/Image.tsx';
import FallbackImage from '@/assets/Images/Fallback-Image.png';
import Button from '@/components/ui/Button/Button.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getPlacesToRelax, getSuggestedRestaurants, getTransportationToHotel, getUpcomingTrips } from '@/api/trips.ts';
import StarsIcon from '@/assets/Icons/Stars.svg?react';
import MarkerPinIcon from '@/assets/Icons/Marker-Pin.svg?react';
import CalendarIcon from '@/assets/Icons/Calendar-Icon.svg?react';
import moment from 'moment';
import SearchIcon from '@/assets/Icons/Search.svg?react';
import MultipleAirportIcon from '@/assets/Icons/Multiple-Airports.svg?react';
import RamenDiningIcon from '@/assets/Icons/Ramen-Dining.svg?react';
import VisibilityIcon from '@/assets/Icons/Visibility.svg?react';
import RetryIcon from '@/assets/Icons/Retry.svg?react';
// @ts-ignore
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import NoUpcomingTripsIllustration from '@/assets/Illustrations/No-Upcoming-Trips.svg?react';
import Skeleton from 'react-loading-skeleton';
import Flyout, { FlyoutCloseButton } from '@/components/ui/Flyout/Flyout.tsx';
import classNames from 'classnames';
import Accordion from '@/components/ui/Accordion/Accordion.tsx';
import { chunk, isEmpty, last, upperFirst } from 'lodash';
import { formatDistance, formatDuration } from '@/utils/stringUtils.ts';
import MapPreview from '@/components/MapPreview/MapPreview.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import RestaurantFallbackImage from '@/assets/Images/Restaurant-Placeholder.jpeg';
import TrainIcon from '@/assets/Icons/Tram-Fill.svg?react';
import ManWalkingIcon from '@/assets/Icons/Man-Walking.svg?react';
import BikeIcon from '@/assets/Icons/Bike.svg?react';
import CarIcon from '@/assets/Icons/Car-Fill.svg?react';
import { useModalStore } from '@/store/useModalStore.ts';
import Card from '@/components/ui/Card/Card.tsx';
import { Form, FormikProvider, useFormik } from 'formik';
import FormikAutocompletePlaceInput from '@/components/Formik/FormikAutocompletePlaceInput.tsx';
import { searchCity, searchLodging } from '@/api/search.ts';
import MapModal from '@/pages/MyClimate/UpcomingTrips/MapModal.tsx';
import ContentCard from '@/pages/MyClimate/UpcomingTrips/ContentCard.tsx';
import TripPlace = App.Data.TripPlace;
import ListPlacesResponse = App.Data.Response.ListPlacesResponse;
import TransportationMode = App.Enums.TransportationMode;
import GoogleDirectionsRouteData = App.Data.GoogleDirectionsRouteData;
import RestaurantData = App.Data.RestaurantData;

interface UpcomingTripsFlyoutProps {
	upcomingTrips: ListPlacesResponse;
	id?: number;
}

interface Marker {
	lat: number;
	lng: number;
}

interface MapModalData {
	route?: GoogleDirectionsRouteData;
	markers?: Marker[];
}

interface TripDateBadgeProps {
	date: string | Date;
}

const getIconByMode = (mode: TransportationMode) => {
	const icons = {
		transit: <TrainIcon />,
		walking: <ManWalkingIcon />,
		driving: <CarIcon />,
		bicycling: <BikeIcon />,
	};

	return icons[mode] || null;
};

const dynamicRefetchInterval = (intervalMs: number) => {
	return (data: { state?: { data?: { complete?: boolean } } }) => {
		return !data?.state?.data?.complete ? intervalMs : false;
	};
};

const renderMonthInfo = (selectedTrip: string) => {
	const inputDate = moment(selectedTrip);
	const currentDate = moment();

	const inputMonth = inputDate.month();
	const currentMonth = currentDate.month();
	const inputYear = inputDate.year();
	const currentYear = currentDate.year();

	const monthDifference = (inputYear - currentYear) * 12 + (inputMonth - currentMonth);

	if (monthDifference === 0) {
		return 'this month';
	} else if (monthDifference === 1) {
		return 'next month';
	} else {
		return `${monthDifference} months`;
	}
};

const TripDateBadge: React.FC<TripDateBadgeProps> = ({ date }) => {
	return (
		<FlexBlock flexDirection="column" className={styles.tripDateBadgeWrapper}>
			<FlexBlock justifyContent="center" alignItems="center" className={styles.month}>
				{moment(date).format('MMM').toUpperCase()}
			</FlexBlock>
			<FlexBlock justifyContent="center" alignItems="center" className={styles.day}>
				{moment(date).format('DD')}
			</FlexBlock>
		</FlexBlock>
	);
};

const UpcomingTripsFlyout: React.FC<UpcomingTripsFlyoutProps> = ({ upcomingTrips, id }) => {
	const hasTrips = upcomingTrips?.places && upcomingTrips.places.length > 0;
	const selectedTrip = typeof id === 'number' && upcomingTrips.places[id] ? upcomingTrips.places[id] : null;
	const [isResourcesAccordionOpen, setIsResourcesAccordionOpen] = useState(!!id);
	const [chosenTransportationMode, setChosenTransportationMode] = useState<TransportationMode | null>(null);
	const [mapModalData, setMapModalData] = useState<MapModalData>({});
	const { openModal } = useModalStore(state => state);
	// Pagination page index for "Restaurants" and "Places to Relax"
	const [restaurantsPage, setRestaurantsPage] = useState(0);
	const [placesToRelaxPage, setPlacesToRelaxPage] = useState(1);
	// Used to simulate loading state and to show skeleton (when the API request/JS code is executed almost instantly)
	const [simulateRestaurantsLoading, setSimulateRestaurantsLoading] = useState(false);
	const [simulatePlacesToRelaxLoading, setSimulatePlacesToRelaxLoading] = useState(false);
	const [chosenGooglePlaceId, setChosenGooglePlaceId] = useState<string | null>(null);

	const {
		data: transportationData,
		isLoading: transportationDataLoading,
		refetch: transportationDataRefetch,
	} = useQuery({
		queryKey: [queryKeys.transportationToHotel, selectedTrip?.coordinates || chosenGooglePlaceId],
		queryFn: () =>
			getTransportationToHotel({
				coordinates: selectedTrip!.coordinates || chosenGooglePlaceId,
				airportIataCode: selectedTrip?.iataAirportCode || '',
			}),
		enabled: !!selectedTrip?.iataAirportCode || !!chosenGooglePlaceId,
		refetchInterval: dynamicRefetchInterval(1000),
	});
	const {
		data: suggestedRestaurants,
		isLoading: suggestedRestaurantsLoading,
		isSuccess: suggestedRestaurantsSuccess,
		refetch: suggestedRestaurantsRefetch,
	} = useQuery({
		queryKey: [queryKeys.suggestedRestaurants, selectedTrip?.coordinates],
		queryFn: () =>
			getSuggestedRestaurants({
				coordinates: selectedTrip?.coordinates || null,
				googlePlaceId: chosenGooglePlaceId!,
			}),
		enabled: !!selectedTrip || !!chosenGooglePlaceId,
		refetchInterval: dynamicRefetchInterval(1000),
	});
	const {
		data: suggestedPlacesToRelax,
		isLoading: suggestedPlacesToRelaxLoading,
		isSuccess: suggestedPlacesToRelaxSuccess,
		refetch: suggestedPlacesToRelaxRefetch,
	} = useQuery({
		queryKey: [queryKeys.suggestedPlacesToRelax, selectedTrip?.coordinates],
		queryFn: () =>
			getPlacesToRelax({
				coordinates: selectedTrip?.coordinates || null,
				googlePlaceId: chosenGooglePlaceId!,
				page: placesToRelaxPage,
			}),
		enabled: !!selectedTrip || !!chosenGooglePlaceId,
		refetchInterval: dynamicRefetchInterval(3000),
	});

	const formik = useFormik({
		initialValues: {
			city: undefined,
			hotel: undefined,
		},
		onSubmit: values => {
			setChosenGooglePlaceId(null);
			setChosenGooglePlaceId(values.hotel || values.city || null);
		},
	});

	useEffect(() => {
		if (formik.values.city && formik.values.hotel) {
			formik.setFieldValue('hotel', null);
		}
	}, [formik.setFieldValue, formik.values.city]);

	useEffect(() => {
		if (selectedTrip || chosenGooglePlaceId) {
			suggestedPlacesToRelaxRefetch();
		}
	}, [placesToRelaxPage]);

	// Paginate restaurants and get the current selected page slice
	const restaurantsPerPage = 3;
	const displayedRestaurants: RestaurantData[] =
		chunk(suggestedRestaurants?.restaurants || [], restaurantsPerPage)[restaurantsPage] || [];

	// Get final loading states of "Restaurants" and "Places to Relax" (including both backend and simulated loadings)
	const isRestaurantsLoading = simulateRestaurantsLoading || suggestedRestaurantsLoading;
	const isPlacesToRelaxLoading = simulatePlacesToRelaxLoading || suggestedPlacesToRelaxLoading;

	const handleOpenMapWithRoute = (route: GoogleDirectionsRouteData) => {
		setMapModalData({ route });
		openModal('mapModal');
	};

	// Used in map modal within "Restaurants" and "Places to Relax" sections
	const handleOpenMapWithMarker = (marker: Marker) => {
		setMapModalData({ markers: [marker] });
		openModal('mapModal');
	};

	const handlePageChange = async (section: string, direction: 'next' | 'previous') => {
		const isNext = direction === 'next';

		if (section === 'restaurants') {
			setSimulateRestaurantsLoading(true);
			setTimeout(() => {
				setSimulateRestaurantsLoading(false);

				if (isNext) {
					if (
						suggestedRestaurants?.restaurants &&
						last(displayedRestaurants) !== last(suggestedRestaurants?.restaurants)
					) {
						setRestaurantsPage(restaurantsPage + 1);
					}
				} else {
					if (restaurantsPage > 0) {
						setRestaurantsPage(restaurantsPage - 1);
					}
				}
			}, 500);
		} else if (section === 'placesToRelax') {
			if (isNext || (!isNext && placesToRelaxPage > 1)) {
				setSimulatePlacesToRelaxLoading(true);
				setPlacesToRelaxPage(isNext ? placesToRelaxPage + 1 : placesToRelaxPage - 1);
				setTimeout(() => setSimulatePlacesToRelaxLoading(false), 300);
			}
		}
	};

	const loadResources = async () => {
		if (!selectedTrip && !chosenGooglePlaceId) {
			setIsResourcesAccordionOpen(false);
			return;
		}

		await Promise.all([
			!!selectedTrip?.iataAirportCode && transportationDataRefetch(),
			suggestedRestaurantsRefetch(),
			suggestedPlacesToRelaxRefetch(),
		]);

		if (transportationData?.modes) {
			setChosenTransportationMode(Object.keys(transportationData.modes)[0] as TransportationMode);
		}

		if (isResourcesAccordionOpen) {
			setSimulateRestaurantsLoading(true);
			setTimeout(() => setSimulateRestaurantsLoading(false), 500);
		} else {
			setIsResourcesAccordionOpen(true);
		}
	};

	useEffect(() => {
		loadResources();
	}, [id, selectedTrip, chosenGooglePlaceId]);

	useEffect(() => {
		transportationData?.modes &&
			setChosenTransportationMode(Object.keys(transportationData.modes)[0] as TransportationMode);
	}, [transportationData]);

	return (
		<Flyout
			id={id?.toString() || 'upcomingTripsFlyout'}
			button={
				hasTrips ? (
					<Button color="darkGreen" size="small" hover={false} className={styles.planTripBttn}>
						<StarsIcon /> Plan trip
					</Button>
				) : (
					<Button color="darkGreen" size="small">
						Discover
					</Button>
				)
			}
			flyoutWrapperClassName={hasTrips ? styles.flyoutButtonWrapper : ''}
			className={styles.flyout}
		>
			<FlyoutCloseButton className={styles.closeBttn} />
			<div className={styles.flyoutContentWrapper}>
				<h2>Your AI travel companion itinerary</h2>

				{selectedTrip ? (
					<FlexBlock className={styles.selectedTripCard}>
						<TripDateBadge date={selectedTrip.arrivalAt} />
						<div className={styles.imageWrapper}>
							<Image src={selectedTrip?.cityImageUrl || ''} fallbackSrc={FallbackImage} />
						</div>
						<FlexBlock justifyContent="space-between" className={styles.desc}>
							<div>
								<p className={styles.city}>{selectedTrip.city}</p>
								<FlexBlock columnGap="6px" alignItems="center" className={styles.tripInfo}>
									<MarkerPinIcon />
									<p>
										{selectedTrip.city}, {selectedTrip.country}
									</p>
								</FlexBlock>
								<FlexBlock columnGap="6px" alignItems="center" className={styles.tripInfo}>
									<CalendarIcon />
									<p>{moment(selectedTrip.arrivalAt).format('DD MMM YYYY')}</p>
								</FlexBlock>
								<span className={styles.timeToTripInfo}>{renderMonthInfo(selectedTrip.arrivalAt)}</span>
							</div>
							<div className={styles.aiIconWrapper}>
								<StarsIcon />
							</div>
						</FlexBlock>
					</FlexBlock>
				) : (
					// Search/discover place
					<Card className={styles.searchPlaceCard}>
						<FormikProvider value={formik}>
							<Form>
								<FlexBlock
									flexDirection={{
										[breakpoints.zero]: 'column',
										[breakpoints.tabletWide]: isEmpty(upcomingTrips?.places) ? 'row' : 'column',
									}}
									columnGap="32px"
									rowGap="32px"
								>
									<FormikAutocompletePlaceInput
										name="city"
										label="Destination"
										placeholder="Search by city"
										fetchFn={query => searchCity(query)}
									/>
									<FormikAutocompletePlaceInput
										name="hotel"
										label="Hotel"
										placeholder="Search by hotel (optional)"
										fetchFn={query => searchLodging(formik.values.city || '', query)}
										disabled={!formik.values.city}
										key={formik.values.city}
									/>
								</FlexBlock>
								<Button
									type="submit"
									size="medium"
									color="darkGreen"
									disabled={!formik.values.city}
									className={styles.searchBttn}
								>
									Search
								</Button>
							</Form>
						</FormikProvider>
					</Card>
				)}

				{/*	Accordion Section */}
				<FlexBlock
					flexDirection="column"
					rowGap="16px"
					className={classNames(styles.accordionContent, isResourcesAccordionOpen)}
				>
					{/* Transportation accordion*/}
					{selectedTrip && selectedTrip.iataAirportCode && (
						<Accordion title="Transportation from airport" leftIcon={<MultipleAirportIcon />}>
							<FlexBlock flexDirection="column" rowGap="44px" className={styles.mapAccordion}>
								{!transportationDataLoading && transportationData?.complete ? (
									<>
										<FlexBlock columnGap="24px">
											{transportationData?.modes &&
												Object.entries(transportationData.modes).map(([mode, data], index) => (
													<div
														className={classNames(
															styles.transportationMode,
															mode === chosenTransportationMode && styles.selected,
														)}
														onClick={() =>
															setChosenTransportationMode(mode as TransportationMode)
														}
														key={index}
													>
														<p>{upperFirst(mode)}</p>

														<p>{formatDuration(data.duration)}</p>

														<div className={styles.iconWrapper}>
															{getIconByMode(mode as TransportationMode)}
														</div>
													</div>
												))}
										</FlexBlock>
										<FlexBlock justifyContent="space-between" columnGap="20px" rowGap="20px">
											{chosenTransportationMode &&
												transportationData?.modes[chosenTransportationMode]?.steps && (
													<div className={styles.stepsContainer}>
														<p className={styles.stepsTitle}>Transit</p>
														<ul>
															{transportationData.modes[chosenTransportationMode].steps
																.length > 0 &&
																transportationData.modes[
																	chosenTransportationMode
																].steps?.map((step, index) => (
																	<li key={index}>
																		{step.text} ({formatDistance(step.distance)},{' '}
																		{formatDuration(step.duration)})
																	</li>
																))}
														</ul>
													</div>
												)}

											{transportationData ? (
												chosenTransportationMode && (
													<MapPreview
														mode={chosenTransportationMode}
														route={transportationData.modes[chosenTransportationMode]}
														onOpenModal={handleOpenMapWithRoute}
													/>
												)
											) : (
												<Skeleton width="560px" height="320px" />
											)}
										</FlexBlock>
									</>
								) : (
									<FlexBlock flexDirection="column" rowGap="24px">
										<FlexBlock columnGap="15px">
											<Skeleton width="110px" height="110px" />
											<Skeleton width="110px" height="110px" />
										</FlexBlock>
										<FlexBlock justifyContent="space-between">
											<FlexBlock
												flexDirection="column"
												rowGap="8px"
												style={{ minHeight: '260px' }}
											>
												<Skeleton width="150px" height="20px" />
												{Array(4)
													.fill(null)
													.map((_, index) => (
														<Skeleton key={index} width="350px" height="20px" />
													))}
											</FlexBlock>
											<Skeleton width="460px" height="260px" />
										</FlexBlock>
									</FlexBlock>
								)}
							</FlexBlock>
						</Accordion>
					)}

					{/*Restaurants accordion*/}
					{(suggestedRestaurantsSuccess || isRestaurantsLoading) && (
						<Accordion title="Eco-friendly places to eat" leftIcon={<RamenDiningIcon />}>
							<FlexBlock
								columnGap="12px"
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tabletWide]: 'row',
								}}
								rowGap="20px"
								alignItems={{
									[breakpoints.zero]: 'center',
									[breakpoints.tabletWide]: 'unset',
								}}
							>
								{isRestaurantsLoading || !suggestedRestaurants?.complete ? (
									<div className={classNames(styles.skeletonRestaurantsWrapper)}>
										{Array.from({ length: 3 }).map((_, index) => (
											<FlexBlock
												flexDirection="column"
												justifyContent="space-between"
												key={index}
												className={styles.skeletonCard}
											>
												<FlexBlock flexDirection="column">
													<Skeleton
														width="250px"
														height="125px"
														style={{ marginBottom: '10px' }}
													/>
													<Skeleton width="200px" height="24px" />
												</FlexBlock>

												<FlexBlock flexDirection="column" columnGap="20px">
													<Skeleton width="250px" height="120px" />
												</FlexBlock>

												<FlexBlock
													flexDirection="row"
													justifyContent="center"
													style={{ marginBottom: '14px' }}
												>
													<Skeleton width="250px" height="24px" />
												</FlexBlock>

												<FlexBlock
													flexDirection="row"
													justifyContent="center"
													style={{ marginTop: 'auto' }}
												>
													<Skeleton width="250px" height="36px" />
												</FlexBlock>
											</FlexBlock>
										))}
									</div>
								) : (
									displayedRestaurants &&
									displayedRestaurants.length > 0 &&
									displayedRestaurants.map((restaurant, index) => (
										<ContentCard
											key={index}
											image={
												<Image
													src={restaurant.photoUrl || ''}
													fallbackSrc={RestaurantFallbackImage}
												/>
											}
											title={restaurant.name || ''}
											rating={restaurant.rating}
											expensiveness={restaurant.priceLevel || 0}
											website={restaurant.website}
											description={
												restaurant?.reviews && restaurant?.reviews.length > 0
													? restaurant?.reviews[0]?.text
													: null
											}
											location={restaurant.coordinates}
											googlePlaceId={restaurant.googlePlaceId}
											distance={`${restaurant.distance && formatDistance(restaurant.distance)} from the ${selectedTrip?.hasHotelBooking ? 'hotel' : 'city center'}`}
											reviews={
												restaurant?.reviews && restaurant?.reviews?.length > 0
													? restaurant.reviews
													: null
											}
											onSeeLocationClick={value => handleOpenMapWithMarker(value)}
											arrivalAt={selectedTrip?.arrivalAt ? moment(selectedTrip.arrivalAt) : null}
										/>
									))
								)}
							</FlexBlock>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.phoneWide]: 'row',
								}}
								alignItems="center"
								justifyContent="center"
								rowGap="20px"
								columnGap="20px"
								className={styles.searchMoreWrapper}
							>
								<Button
									hover={false}
									size="medium"
									onClick={() => handlePageChange('restaurants', 'previous')}
									disabled={
										!suggestedRestaurants?.complete || isRestaurantsLoading || restaurantsPage === 0
									}
									className={styles.previousPageBttn}
								>
									Previous page
								</Button>
								<Button
									hover={false}
									size="medium"
									onClick={() => handlePageChange('restaurants', 'next')}
									disabled={
										!suggestedRestaurants?.complete ||
										isRestaurantsLoading ||
										last(displayedRestaurants) === last(suggestedRestaurants?.restaurants)
									}
									leftIcon={<RetryIcon />}
								>
									View more
								</Button>
							</FlexBlock>
						</Accordion>
					)}

					{/*Places to visit accordion*/}
					{(suggestedPlacesToRelaxSuccess || isPlacesToRelaxLoading) && (
						<Accordion title="Places to visit" leftIcon={<VisibilityIcon />}>
							<FlexBlock
								columnGap="20px"
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tabletWide]: 'row',
								}}
								rowGap="20px"
								alignItems={{
									[breakpoints.zero]: 'center',
									[breakpoints.tabletWide]: 'unset',
								}}
							>
								{!suggestedPlacesToRelaxSuccess ||
								isPlacesToRelaxLoading ||
								!suggestedPlacesToRelax?.complete ? (
									<div className={classNames(styles.skeletonRestaurantsWrapper)}>
										{Array.from({ length: 3 }).map((_, index) => (
											<FlexBlock
												flexDirection="column"
												justifyContent="space-between"
												key={index}
												className={styles.skeletonCard}
											>
												<FlexBlock flexDirection="column">
													<Skeleton
														width="250px"
														height="125px"
														style={{ marginBottom: '10px' }}
													/>
													<Skeleton width="200px" height="24px" />
												</FlexBlock>

												<FlexBlock flexDirection="column" columnGap="20px">
													<Skeleton width="250px" height="120px" />
												</FlexBlock>

												<FlexBlock
													flexDirection="row"
													justifyContent="center"
													style={{ marginBottom: '14px' }}
												>
													<Skeleton width="250px" height="24px" />
												</FlexBlock>

												<FlexBlock
													flexDirection="row"
													justifyContent="center"
													style={{ marginTop: 'auto' }}
												>
													<Skeleton width="250px" height="36px" />
												</FlexBlock>
											</FlexBlock>
										))}
									</div>
								) : (
									suggestedPlacesToRelax &&
									suggestedPlacesToRelax.placesToRelax.length > 0 &&
									suggestedPlacesToRelax.placesToRelax.map((place, index) => (
										<ContentCard
											key={index}
											image={
												<Image
													src={place.photoUrl || ''}
													fallbackSrc={RestaurantFallbackImage}
												/>
											}
											title={place.name}
											website={place.website}
											description={place.description}
											location={place.coordinates}
											googlePlaceId={place.googlePlaceId}
											distance={`${formatDistance(place.distance)} from the ${selectedTrip?.hasHotelBooking ? 'hotel' : 'city center'}`}
											onSeeLocationClick={value => handleOpenMapWithMarker(value)}
											arrivalAt={
												selectedTrip?.arrivalAt
													? moment.parseZone(selectedTrip.arrivalAt)
													: null
											}
										/>
									))
								)}
							</FlexBlock>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.phoneWide]: 'row',
								}}
								alignItems="center"
								justifyContent="center"
								rowGap="20px"
								columnGap="20px"
								className={styles.searchMoreWrapper}
							>
								<Button
									hover={false}
									size="medium"
									disabled={
										!suggestedPlacesToRelax?.complete ||
										isPlacesToRelaxLoading ||
										placesToRelaxPage === 1
									}
									onClick={() => handlePageChange('placesToRelax', 'previous')}
									className={styles.previousPageBttn}
								>
									Previous page
								</Button>
								<Button
									hover={false}
									size="medium"
									onClick={() => handlePageChange('placesToRelax', 'next')}
									leftIcon={<SearchIcon />}
									disabled={!suggestedPlacesToRelax?.complete || isPlacesToRelaxLoading}
								>
									View more
								</Button>
							</FlexBlock>
						</Accordion>
					)}
				</FlexBlock>
			</div>
			{mapModalData && (
				<MapModal mode={chosenTransportationMode!} route={mapModalData.route} markers={mapModalData.markers} />
			)}
		</Flyout>
	);
};

const UpcomingTrips: React.FC = () => {
	const {
		data: upcomingTrips,
		isLoading,
		isError,
		isSuccess,
	} = useQuery({
		queryKey: [queryKeys.upcomingTrips],
		queryFn: getUpcomingTrips,
	});

	const hasTrips = upcomingTrips?.places && upcomingTrips.places.length > 0;

	return (
		<div className={styles.myClimateUpcomingTripsWrapper}>
			<h2>Itinerary ideas for your trip</h2>
			<p className={styles.subtitle}>Choose from your upcoming destinations</p>

			<div className={styles.carouselWrapper}>
				{isLoading && (
					<div className={styles.loadingWrapper}>
						<Skeleton height="170px" width="100%" />
					</div>
				)}

				{isError && (
					<div className={styles.errorWrapper}>
						<p>Oops! We're encountering an issue retrieving your trips. Please try again later.</p>
					</div>
				)}

				{isSuccess && hasTrips && (
					<Splide
						hasTrack={false}
						options={{
							type: 'slide',
							perPage: 1,
							focus: 'center',
							gap: '58px',
							padding: { right: '33%' },
							pagination: false,
							arrows: true,
						}}
					>
						<div className="splide__arrows">
							<FlexBlock columnGap="6px" className={styles.carouselButtons}>
								<button className="splide__arrow--prev">
									<ArrowRight />
								</button>
								<button className="splide__arrow--next">
									<ArrowRight />
								</button>
							</FlexBlock>
						</div>

						<SplideTrack className={styles.sliderWrapper}>
							{upcomingTrips.places.map((place: TripPlace, index) => (
								<SplideSlide key={index}>
									<FlexBlock className={styles.tripCard}>
										<TripDateBadge date={place.arrivalAt} />
										<div className={styles.imageWrapper}>
											<Image
												src={place?.cityImageThumbnailUrl || ''}
												fallbackSrc={FallbackImage}
											/>
										</div>

										<div className={styles.cardDesc}>
											<p className={styles.city}>{place.city}</p>
											<FlexBlock columnGap="6px" alignItems="center" className={styles.tripInfo}>
												<MarkerPinIcon />
												<p>
													{place.city}, {place.country}
												</p>
											</FlexBlock>
											<FlexBlock columnGap="6px" alignItems="center" className={styles.tripInfo}>
												<CalendarIcon />
												<p>{moment(place.arrivalAt).format('DD MMM YYYY')}</p>
											</FlexBlock>
											<UpcomingTripsFlyout key={index} upcomingTrips={upcomingTrips} id={index} />
										</div>
									</FlexBlock>
								</SplideSlide>
							))}
						</SplideTrack>
					</Splide>
				)}

				{isSuccess && !hasTrips && (
					<FlexBlock className={styles.noTripCard}>
						<div className={styles.imageWrapper}>
							<NoUpcomingTripsIllustration />
						</div>
						<div className={styles.noTripDesc}>
							<p>No trips yet...</p>
							<p>Not a problem! You can still explore our AI trip companion</p>
							<UpcomingTripsFlyout key="noTrips" upcomingTrips={upcomingTrips} />
						</div>
					</FlexBlock>
				)}
			</div>
		</div>
	);
};

export default UpcomingTrips;
