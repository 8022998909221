import { FC } from 'react';
import { useField } from 'formik';
import SwitchSelect, { SwitchSelectProps } from '@/components/ui/SwitchSelect/SwitchSelect.tsx';

interface FormikSwitchSelectProps
	extends Omit<SwitchSelectProps, 'value' | 'onChange' | 'errorMessage' | 'touched' | 'initialSelected'> {
	name: string;
}

const FormikSwitchSelect: FC<FormikSwitchSelectProps> = ({ name, ...props }) => {
	const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);

	return (
		<SwitchSelect
			{...props}
			name={name}
			onBlur={onBlur}
			value={value}
			onChange={newValue => setValue(newValue)}
			errorMessage={error}
			touched={touched}
		/>
	);
};

export default FormikSwitchSelect;
