import React from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import styles from './UnsavedChangesModal.module.scss';
import Button from '@/components/ui/Button/Button.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { useModalStore } from '@/store/useModalStore.ts';

const UnsavedChangesModal: React.FC = () => {
	const { closeModal, data, activeModal } = useModalStore(state => state);

	return activeModal === 'unsavedChanges' ? (
		<Modal name="unsavedChanges" className={styles.unsavedChangesModalWrapper} onClose={closeModal}>
			<p>Unsaved changes</p>
			<p>Your changes will be lost if you leave this page</p>
			<FlexBlock columnGap="24px" className={styles.bttnsWrapper}>
				<Button
					size="large"
					color="default"
					hover={false}
					onClick={() => {
						closeModal();
					}}
				>
					Return
				</Button>
				<Button size="large" color="darkGreen" onClick={data.onConfirm}>
					Leave, don’t save
				</Button>
			</FlexBlock>
		</Modal>
	) : null;
};

export default UnsavedChangesModal;
