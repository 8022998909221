import React from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import Welcome from '@/pages/MyClimate/Welcome.tsx';
import UpcomingTrips from '@/pages/MyClimate/UpcomingTrips/UpcomingTrips.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

const MyClimate: React.FC = () => {
	return (
		<MainLayout>
			<Container>
				<FlexBlock flexDirection="column" rowGap="32px">
					<Welcome />
					<UpcomingTrips />
				</FlexBlock>
			</Container>
		</MainLayout>
	);
};

export default MyClimate;
