import React, { useEffect, useState } from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './Allocation.module.scss';
import { FieldArray, Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import {
	getAllocationEntities,
	getAllocationResponsiblePersons,
	getAllocationTables,
	updateAllocationTables,
} from '@/api/climatePro.ts';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import DeleteIcon from '@/assets/Icons/Trash-Bin.svg?react';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import { legalEntitiesValidationSchema } from '@/validations/climatePro.ts';
import { departmentsDemoColumns, legalEntitiesDemoColumns, officesDemoColumns } from '@/constants/climatePro.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import Skeleton from 'react-loading-skeleton';
import { useRouteBlocker } from '@/hooks/useRouteBlocker.ts';
import AllocationData = App.Data.AllocationData;

const Allocation: React.FC = () => {
	const [isLegalEntityTableEditable, setIsLegalEntityTableEditable] = useState<boolean>(false);
	const [isOfficesTableEditable, setIsOfficesTableEditable] = useState<boolean>(false);
	const [isDepartmentTableEditable, setIsDepartmentTableEditable] = useState<boolean>(false);
	// Show unsaved changes modal when there is a new form values and user wants to navigate to another page
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	useRouteBlocker(hasUnsavedChanges);

	const { data: allocationEntitiesData, isLoading: isAllocationEntitiesDataLoading } = useQuery({
		queryKey: [queryKeys.allocationEntities],
		queryFn: getAllocationEntities,
	});

	const { data: allocationResponsiblePersonsData, isLoading: isAllocationResponsiblePersonsLoading } = useQuery({
		queryKey: [queryKeys.responsibleUsers],
		queryFn: getAllocationResponsiblePersons,
	});

	const { data: allocationTables, isLoading: isAllocationTablesLoading } = useQuery({
		queryKey: [queryKeys.allocationTables],
		queryFn: getAllocationTables,
	});

	const legalEntitiesFormik = useFormik<{ legalEntities: AllocationData[] }>({
		initialValues: {
			legalEntities: allocationTables?.legalEntities || [],
		},
		enableReinitialize: true,
		validationSchema: legalEntitiesValidationSchema,
		onSubmit: (values, formikHelpers) => handleSaveLegalEntityChanges(values, formikHelpers),
	});

	const officesFormik = useFormik<{ offices: AllocationData[]; legalEntityId: number | undefined }>({
		initialValues: {
			legalEntityId: allocationEntitiesData?.legalEntities[0].id || undefined,
			offices: allocationTables?.offices || [],
		},
		enableReinitialize: true,
		validationSchema: legalEntitiesValidationSchema,
		onSubmit: (values, formikHelpers) => handleSaveOfficesChanges(values, formikHelpers),
	});

	const departmentsFormik = useFormik<{ departments: AllocationData[] }>({
		initialValues: {
			departments: allocationTables?.departments || [],
		},
		enableReinitialize: true,
		validationSchema: legalEntitiesValidationSchema,
		onSubmit: (values, formikHelpers) => handleSaveDepartmentsChanges(values, formikHelpers),
	});

	// Update office table data when legal entity is changed
	const handleLegalEntityChange = (selectedId: number) => {
		officesFormik.setValues({
			legalEntityId: selectedId,
			offices: allocationTables?.offices?.filter(office => office.legalEntityId === selectedId) || [],
		});
	};

	useEffect(() => {
		officesFormik.values.legalEntityId && handleLegalEntityChange(officesFormik.values.legalEntityId);
	}, [officesFormik.values.legalEntityId]);

	useEffect(() => {
		setHasUnsavedChanges(isLegalEntityTableEditable || isOfficesTableEditable || isDepartmentTableEditable);
	}, [isLegalEntityTableEditable, isOfficesTableEditable, isDepartmentTableEditable]);

	const handleSaveLegalEntityChanges = async (data: any, formikHelpers: FormikHelpers<any>) => {
		const allocationData = {
			allocations: data.legalEntities,
			modelClass: 'legal_entity' as App.Enums.AllocationModelClass,
		};
		try {
			await updateAllocationTables(allocationData);
			setIsLegalEntityTableEditable(false);
		} catch (error: any) {
			if (error.response?.data?.data?.fields) {
				error.response.data.data.fields = Object.fromEntries(
					Object.entries(error.response.data.data.fields).map(([key, value]) => {
						const newKey = key.replace(/^allocations/, 'legalEntities');
						return [newKey, value];
					}),
				);
			}

			handleResponseErrors(error, formikHelpers);
		}
	};
	const handleSaveOfficesChanges = async (data: any, formikHelpers: FormikHelpers<any>) => {
		const allocationData = { allocations: data.offices, modelClass: 'office' as App.Enums.AllocationModelClass };
		try {
			await updateAllocationTables(allocationData);
			setIsOfficesTableEditable(false);
		} catch (error: any) {
			if (error.response?.data?.data?.fields) {
				error.response.data.data.fields = Object.fromEntries(
					Object.entries(error.response.data.data.fields).map(([key, value]) => {
						const newKey = key.replace(/^allocations/, 'offices');
						return [newKey, value];
					}),
				);
			}

			handleResponseErrors(error, formikHelpers);
		}
	};
	const handleSaveDepartmentsChanges = async (data: any, formikHelpers: FormikHelpers<any>) => {
		const allocationData = {
			allocations: data.departments,
			modelClass: 'department' as App.Enums.AllocationModelClass,
		};
		try {
			await updateAllocationTables(allocationData);
			setIsDepartmentTableEditable(false);
		} catch (error: any) {
			if (error.response?.data?.data?.fields) {
				error.response.data.data.fields = Object.fromEntries(
					Object.entries(error.response.data.data.fields).map(([key, value]) => {
						const newKey = key.replace(/^allocations/, 'departments');
						return [newKey, value];
					}),
				);
			}

			handleResponseErrors(error, formikHelpers);
		}
	};
	return (
		<FlexBlock flexDirection="column" rowGap="38px" className={styles.allocationWrapper}>
			{/* Legal entities table*/}
			<FormikProvider value={legalEntitiesFormik}>
				<Form onSubmit={legalEntitiesFormik.handleSubmit}>
					<FieldArray
						name="legalEntities"
						render={arrayHelpers => (
							<>
								<h1>Allocate your carbon budget</h1>
								<FlexBlock flexDirection="column" rowGap="38px" className={styles.tableSectionWrapper}>
									<FlexBlock justifyContent="space-between" alignItems="center">
										<h2>Legal entities</h2>
										<Button
											color="darkGreen"
											size="medium"
											align="right"
											className={styles.addButton}
											onClick={() => {
												arrayHelpers.push({
													modelId: undefined,
													legalEntityId: undefined,
													percentage: 0,
													targetCo2: 0,
													responsibleUserId: undefined,
												});
												setIsLegalEntityTableEditable(true);
											}}
											disabled={
												allocationEntitiesData?.legalEntities.length ===
												legalEntitiesFormik.values.legalEntities.length
											}
										>
											Add
										</Button>
									</FlexBlock>

									<div className={styles.tableWrapper}>
										<table>
											<thead>
												<tr>
													{legalEntitiesDemoColumns.map((column, index) => (
														<th key={index}>{column.header}</th>
													))}
												</tr>
											</thead>
											<tbody>
												{legalEntitiesFormik.values.legalEntities.map((item, index) => (
													<tr key={index}>
														<td>
															{isAllocationEntitiesDataLoading && (
																<Skeleton width={120} height={17} />
															)}
															{isLegalEntityTableEditable ? (
																<FormikSelect
																	tableSelect
																	name={`legalEntities.${index}.modelId`}
																	options={
																		allocationEntitiesData?.legalEntities.map(
																			entity => ({
																				value: entity.id,
																				label: entity.name,
																			}),
																		) || []
																	}
																/>
															) : (
																<p>
																	{
																		allocationEntitiesData?.legalEntities.find(
																			entity => entity.id === item.modelId,
																		)?.name
																	}
																</p>
															)}
														</td>
														<td>
															{isLegalEntityTableEditable ? (
																<FormikInput
																	tableInput
																	name={`legalEntities.${index}.percentage`}
																/>
															) : (
																<p>{item.percentage}</p>
															)}
														</td>
														<td>{item.targetCo2}</td>
														<td>
															{isAllocationResponsiblePersonsLoading && (
																<Skeleton width={120} height={17} />
															)}
															{isLegalEntityTableEditable ? (
																<FormikSelect
																	tableSelect
																	searchable
																	name={`legalEntities.${index}.responsibleUserId`}
																	options={
																		allocationResponsiblePersonsData?.responsibleUsers.map(
																			entity => ({
																				value: entity.id,
																				label: entity.fullName,
																			}),
																		) || []
																	}
																/>
															) : (
																<p>
																	{
																		allocationResponsiblePersonsData?.responsibleUsers.find(
																			person =>
																				person.id === item.responsibleUserId,
																		)?.fullName
																	}
																</p>
															)}
														</td>
														{isLegalEntityTableEditable ? (
															<td>
																<Tooltip
																	icon={
																		<button
																			onClick={() => arrayHelpers.remove(index)}
																			className={styles.actionBttn}
																		>
																			<DeleteIcon />
																		</button>
																	}
																	text="Delete row"
																/>
															</td>
														) : (
															<div className={styles.placeholder}></div>
														)}
													</tr>
												))}
											</tbody>
										</table>
										{isLegalEntityTableEditable ? (
											<FlexBlock justifyContent="space-between" alignItems="center">
												<Button
													size="small"
													onClick={() => {
														legalEntitiesFormik.resetForm();
														setIsLegalEntityTableEditable(false);
													}}
													className={styles.cancelBttn}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													color="darkGreen"
													size="small"
													disabled={
														!legalEntitiesFormik.isValid || legalEntitiesFormik.isSubmitting
													}
													className={styles.editSaveBttn}
												>
													Save
												</Button>
											</FlexBlock>
										) : (
											<div className={styles.spinnerEditWrapper}>
												{legalEntitiesFormik.values.legalEntities.length ? (
													<Button
														color="default"
														size="small"
														onClick={() => setIsLegalEntityTableEditable(true)}
														className={styles.editSaveBttn}
													>
														Edit
													</Button>
												) : !isAllocationTablesLoading ? (
													<p className={styles.emptyTable}>No data available</p>
												) : (
													<Spinner absolute />
												)}
											</div>
										)}
									</div>
								</FlexBlock>
							</>
						)}
					/>
				</Form>
			</FormikProvider>

			{/*	Offices table*/}
			<FormikProvider value={officesFormik}>
				<Form onSubmit={officesFormik.handleSubmit}>
					<FieldArray
						name="offices"
						render={arrayHelpers => (
							<>
								<FlexBlock flexDirection="column" rowGap="38px" className={styles.tableSectionWrapper}>
									<FlexBlock justifyContent="space-between" alignItems="center">
										<h2>Offices</h2>
										<FlexBlock columnGap="20px">
											<FormikSelect
												name="legalEntityId"
												options={
													allocationEntitiesData?.legalEntities.map(entity => ({
														value: entity.id,
														label: entity.name,
													})) || []
												}
												className={styles.legalEntitySelect}
											/>
											<Button
												color="darkGreen"
												size="medium"
												align="right"
												className={styles.addButton}
												onClick={() => {
													arrayHelpers.push({
														modelId: undefined,
														legalEntityId: undefined,
														percentage: 0,
														targetCo2: 0,
														responsibleUserId: undefined,
													});
													setIsOfficesTableEditable(true);
												}}
											>
												Add
											</Button>
										</FlexBlock>
									</FlexBlock>

									<div className={styles.tableWrapper}>
										<table>
											<thead>
												<tr>
													{officesDemoColumns.map((column, index) => (
														<th key={index}>{column.header}</th>
													))}
												</tr>
											</thead>
											<tbody>
												{officesFormik.values.offices.map((item, index) => (
													<tr key={index}>
														<td>
															{isAllocationEntitiesDataLoading && (
																<Skeleton width={120} height={17} />
															)}
															{isOfficesTableEditable ? (
																<FormikSelect
																	tableSelect
																	name={`offices.${index}.modelId`}
																	options={
																		allocationEntitiesData?.offices
																			.filter(
																				office =>
																					office.parentId ===
																					officesFormik.values.legalEntityId,
																			)
																			.map(entity => ({
																				value: entity.id,
																				label: entity.name,
																			})) || []
																	}
																/>
															) : (
																<p>
																	{
																		allocationEntitiesData?.offices
																			.filter(
																				office =>
																					office.parentId ===
																					officesFormik.values.legalEntityId,
																			)
																			.find(entity => entity.id === item.modelId)
																			?.name
																	}
																</p>
															)}
														</td>
														<td>
															{isOfficesTableEditable ? (
																<FormikInput
																	tableInput
																	name={`offices.${index}.percentage`}
																/>
															) : (
																<p>{item.percentage}</p>
															)}
														</td>
														<td>{item.targetCo2}</td>
														<td>
															{isAllocationResponsiblePersonsLoading && (
																<Skeleton width={120} height={17} />
															)}
															{isOfficesTableEditable ? (
																<FormikSelect
																	searchable
																	tableSelect
																	name={`offices.${index}.responsibleUserId`}
																	options={
																		allocationResponsiblePersonsData?.responsibleUsers.map(
																			entity => ({
																				value: entity.id,
																				label: entity.fullName,
																			}),
																		) || []
																	}
																/>
															) : (
																<p>
																	{
																		allocationResponsiblePersonsData?.responsibleUsers.find(
																			person =>
																				person.id === item.responsibleUserId,
																		)?.fullName
																	}
																</p>
															)}
														</td>
														{isOfficesTableEditable ? (
															<td>
																<Tooltip
																	icon={
																		<button
																			onClick={() => arrayHelpers.remove(index)}
																			className={styles.actionBttn}
																		>
																			<DeleteIcon />
																		</button>
																	}
																	text="Delete row"
																/>
															</td>
														) : (
															<div className={styles.placeholder}></div>
														)}
													</tr>
												))}
											</tbody>
										</table>
										{isOfficesTableEditable ? (
											<FlexBlock justifyContent="space-between" alignItems="center">
												<Button
													size="small"
													onClick={() => {
														officesFormik.resetForm();
														handleLegalEntityChange(officesFormik.values.legalEntityId!);
														setIsOfficesTableEditable(false);
													}}
													className={styles.cancelBttn}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													color="darkGreen"
													size="small"
													disabled={!officesFormik.isValid || officesFormik.isSubmitting}
													className={styles.editSaveBttn}
												>
													Save
												</Button>
											</FlexBlock>
										) : (
											<div className={styles.spinnerEditWrapper}>
												{officesFormik.values.offices.length ? (
													<Button
														color="default"
														size="small"
														onClick={() => setIsOfficesTableEditable(true)}
														className={styles.editSaveBttn}
													>
														Edit
													</Button>
												) : !isAllocationTablesLoading ? (
													<p className={styles.emptyTable}>No data available</p>
												) : (
													<Spinner absolute />
												)}
											</div>
										)}
									</div>
								</FlexBlock>
							</>
						)}
					/>
				</Form>
			</FormikProvider>

			{/* Departments table*/}
			<FormikProvider value={departmentsFormik}>
				<Form onSubmit={departmentsFormik.handleSubmit}>
					<FieldArray
						name="departments"
						render={arrayHelpers => (
							<>
								<FlexBlock flexDirection="column" rowGap="38px" className={styles.tableSectionWrapper}>
									<FlexBlock justifyContent="space-between" alignItems="center">
										<h2>Departments</h2>
										<Button
											color="darkGreen"
											size="medium"
											align="right"
											className={styles.addButton}
											onClick={() => {
												arrayHelpers.push({
													modelId: undefined,
													legalEntityId: undefined,
													percentage: 0,
													targetCo2: 0,
													responsibleUserId: undefined,
												});
												setIsLegalEntityTableEditable(true);
											}}
											disabled={
												allocationEntitiesData?.departments.length ===
												departmentsFormik.values.departments.length
											}
										>
											Add
										</Button>
									</FlexBlock>

									<div className={styles.tableWrapper}>
										<table>
											<thead>
												<tr>
													{departmentsDemoColumns.map((column, index) => (
														<th key={index}>{column.header}</th>
													))}
												</tr>
											</thead>
											<tbody>
												{departmentsFormik.values.departments.map((item, index) => (
													<tr key={index}>
														<td>
															{isAllocationEntitiesDataLoading && (
																<Skeleton width={120} height={17} />
															)}
															{isDepartmentTableEditable ? (
																<FormikSelect
																	tableSelect
																	name={`departments.${index}.modelId`}
																	options={
																		allocationEntitiesData?.departments.map(
																			entity => ({
																				value: entity.id,
																				label: entity.name,
																			}),
																		) || []
																	}
																/>
															) : (
																<p>
																	{
																		allocationEntitiesData?.departments.find(
																			entity => entity.id === item.modelId,
																		)?.name
																	}
																</p>
															)}
														</td>
														<td>
															{isDepartmentTableEditable ? (
																<FormikInput
																	tableInput
																	name={`departments.${index}.percentage`}
																/>
															) : (
																<p>{item.percentage}</p>
															)}
														</td>
														<td>{item.targetCo2}</td>
														<td>
															{isAllocationResponsiblePersonsLoading && (
																<Skeleton width={120} height={17} />
															)}
															{isDepartmentTableEditable ? (
																<FormikSelect
																	searchable
																	tableSelect
																	name={`departments.${index}.responsibleUserId`}
																	options={
																		allocationResponsiblePersonsData?.responsibleUsers.map(
																			entity => ({
																				value: entity.id,
																				label: entity.fullName,
																			}),
																		) || []
																	}
																/>
															) : (
																<p>
																	{
																		allocationResponsiblePersonsData?.responsibleUsers.find(
																			person =>
																				person.id === item.responsibleUserId,
																		)?.fullName
																	}
																</p>
															)}
														</td>
														{isDepartmentTableEditable ? (
															<td>
																<Tooltip
																	icon={
																		<button
																			onClick={() => arrayHelpers.remove(index)}
																			className={styles.actionBttn}
																		>
																			<DeleteIcon />
																		</button>
																	}
																	text="Delete row"
																/>
															</td>
														) : (
															<div className={styles.placeholder}></div>
														)}
													</tr>
												))}
											</tbody>
										</table>
										{isDepartmentTableEditable ? (
											<FlexBlock justifyContent="space-between" alignItems="center">
												<Button
													size="small"
													onClick={() => {
														departmentsFormik.resetForm();
														setIsDepartmentTableEditable(false);
													}}
													className={styles.cancelBttn}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													color="darkGreen"
													size="small"
													disabled={
														!departmentsFormik.isValid || departmentsFormik.isSubmitting
													}
													className={styles.editSaveBttn}
												>
													Save
												</Button>
											</FlexBlock>
										) : (
											<div className={styles.spinnerEditWrapper}>
												{departmentsFormik.values.departments.length ? (
													<Button
														color="default"
														size="small"
														onClick={() => setIsDepartmentTableEditable(true)}
														className={styles.editSaveBttn}
													>
														Edit
													</Button>
												) : !isAllocationTablesLoading ? (
													<p className={styles.emptyTable}>No data available</p>
												) : (
													<Spinner absolute />
												)}
											</div>
										)}
									</div>
								</FlexBlock>
							</>
						)}
					/>
				</Form>
			</FormikProvider>
		</FlexBlock>
	);
};

export default Allocation;
