import styles from './ForecastChart.module.scss';
import classNames from 'classnames';
import {
	Area,
	AreaChart,
	CartesianGrid,
	Label,
	Legend,
	LegendProps,
	ReferenceArea,
	ReferenceLine,
	ResponsiveContainer,
	Text,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import CheckboxToggle from '@/components/ui/CheckboxToggle/CheckboxToggle';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import React, { FC, useState } from 'react';
import { useModalStore } from '@/store/useModalStore.ts';
import { bundlingTripsData, directFlightData, Step, travelEconomyClassData } from '@/constants/learnMoreModalData.tsx';
import LearnMoreModal from '@/components/LearnMoreModal/LearnMoreModal.tsx';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { AnalyticsData, DashboardAnalyticsData } from '@/api/dashboardAnalytics.ts';
import useDashboardAnalytics from '@/store/useDashboardAnalytics.ts';
import _, { map } from 'lodash';
import { getEmissionTarget } from '@/api/legacyEmissionTarget.ts';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Overlay from '@/components/ui/Overlay/Overlay';
import ChartTooltip from '@/pages/ClimateProDashboard/ChartTooltip/ChartTooltip.tsx';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import LeafIcon from '@/assets/Icons/Leaf.svg?react';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';

interface ForecastChartProps {
	queryAnalytics: UseQueryResult<DashboardAnalyticsData | null, unknown>;
}

interface StatsProps {
	title: string;
	subtitle: string;
	amount: number;
	hasBackground?: boolean;
}

const Stat: React.FC<StatsProps> = ({ title, subtitle, amount, hasBackground }) => (
	<div className={classNames(styles.budget, hasBackground && styles.hasBackground)}>
		<div className={styles.budgetTitle}>{title}</div>
		<div className={styles.budgetSubtitle}>{subtitle}</div>
		<div className={styles.budgetAmount}>{amount}</div>
	</div>
);

const CustomLegend: React.FC<LegendProps> = ({ payload }) => {
	const variants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<ul className={styles.customLegendWrapper}>
			{payload?.map((entry, index) => (
				<li
					key={`item-${index}`}
					// @ts-ignore
					initial="hidden"
					animate="visible"
					variants={variants}
					transition={{ duration: 0.5, ease: 'easeInOut' }}
				>
					<svg width="18" height="18" viewBox="0 0 20 20">
						<circle cx="10" cy="10" r="6" style={{ fill: entry.color }} />
					</svg>
					<span className={styles.label}>
						{/*@ts-ignore */}
						{entry?.payload?.label || value}
					</span>
				</li>
			))}
		</ul>
	);
};

const CustomTick = (props: any) => {
	const { x, y, payload } = props;
	const monthName = moment(payload?.value, 'YYYY-MM').format('MMM');

	return (
		<g transform={`translate(${x},${y})`}>
			<Text x={0} y={0} dy={16} textAnchor="middle" fill="#4F4F4F" fontSize={10}>
				{monthName}
			</Text>
		</g>
	);
};

const findMaxValue = (analytics: AnalyticsData[]) => {
	const values = _.flatMap(analytics, data => [data.actual, data.directFlights, data.economyClass, data.bundleTrips, data.forecast]);

	return _.max(values.filter(value => value !== undefined)) || 0;
};

const firstForecastLabel = (analytics: AnalyticsData[]) => {
	let forecastFirstLabel = '';

	for (let index = 0; index < analytics.length; index++) {
		const element = analytics[index];
		const isForecastMonth = element?.forecast !== null;

		if (isForecastMonth) {
			forecastFirstLabel = element.label;
			break;
		}
	}

	return forecastFirstLabel;
};

interface Option {
	title: string;
	text: string;
	color: string;
	isActive: boolean;
	steps: Step[];
}

type OptionKey = 'economyClass' | 'bundleTrips' | 'directFlights';

interface OptionsState {
	economyClass: Option;
	bundleTrips: Option;
	directFlights: Option;
}

const initialOptions: OptionsState = {
	economyClass: {
		title: 'Travel economy',
		text: 'trips, less than 8 hours long were on business class',
		color: '#F765A3',
		isActive: false,
		steps: travelEconomyClassData,
	},
	bundleTrips: {
		title: 'Bundle trips',
		text: 'trips, by the same travellers had the same destination within 2 months',
		color: '#9861F6',
		isActive: true,
		steps: bundlingTripsData,
	},
	directFlights: {
		title: 'Take direct flights',
		text: 'air trips, within the last 6 months were multi leg where a direct route existed.',
		color: '#F37E64',
		isActive: false,
		steps: directFlightData,
	},
};

type labelMapKeys = OptionKey | 'actual' | 'forecast';
type LabelMap = {
	[key in labelMapKeys]: { label: string };
};
const labelMap: LabelMap = {
	actual: { label: 'Current emissions' },
	forecast: { label: 'Current emissions forecast' },
	economyClass: { label: 'Travel economy' },
	bundleTrips: { label: 'Bundle trips' },
	directFlights: { label: 'Take direct flight' },
};

const ForecastChart: FC<ForecastChartProps> = ({ queryAnalytics }) => {
	const { contribution, timePeriod } = useDashboardAnalytics();
	const { data, isLoading, isRefetching, isError } = queryAnalytics;

	const emissionQuery = useQuery({
		queryKey: [queryKeys.getEmissionTarget],
		queryFn: getEmissionTarget,
		enabled: true,
	});

	const [options, setOptions] = useState<OptionsState>(initialOptions);
	const { openModal, activeModal } = useModalStore(state => state);

	const dataOptions = data?.options;
	const analytics = data?.analytics;
	const stats = data?.stats;

	const chartData = analytics?.[contribution] || [];
	const budget = emissionQuery?.data?.targetCo2;
	const max = Math.max(findMaxValue(chartData || []) || 0, budget || 0);

	const forecastLabelStart = firstForecastLabel(chartData);
	const forecastLabelEnds = chartData?.[chartData.length - 1]?.label;
	const loading = isLoading || isRefetching;

	const toggleOption = (key: OptionKey) => {
		const updatedOptions = { ...options };

		updatedOptions[key].isActive = !updatedOptions[key].isActive;

		setOptions(updatedOptions);
	};

	if (loading) {
		return (
			<div className={styles.forestChartWrapper}>
				<FlexBlock flexDirection="column" rowGap="12px" className={styles.optionsColumn}>
					{Array.from({ length: 4 }).map((_, index) => (
						<Skeleton width="288px" height="150px" key={index} />
					))}
				</FlexBlock>
				<FlexBlock flexDirection="column" rowGap="22px" className={styles.chartColumn}>
					<FlexBlock columnGap="20px" justifyContent="space-between" className={styles.stats}>
						{Array.from({ length: 3 }).map((_, index) => (
							<Skeleton width="250px" height="150px" key={index} />
						))}
					</FlexBlock>
					<Skeleton height="500px" width="100%" />
				</FlexBlock>
				<Skeleton width="100%" height="30px" />
			</div>
		);
	}

	const actualEmissionStatSubtitle = {
		company: 'Company',
		department: 'Department',
		legalEntity: 'Legal Entity',
		me: 'My',
	}[contribution];

	const optionsArray = Object.entries(options) as [OptionKey, Option][];

	return (
		<>
			<div className={styles.forestChartWrapper}>
				<FlexBlock flexDirection="column" rowGap="12px" className={styles.optionsColumn}>
					{optionsArray.map(([optionKey, { title, text, steps, isActive }]) => {
						// @ts-ignore
						const optionData = dataOptions?.[contribution]?.[optionKey];
						return (
							<div className={classNames(styles.option, isActive ? styles.isActive : '')} key={optionKey}>
								<LearnMoreModal name={`learnMoreModal_${optionKey}`} steps={steps} key={activeModal} />
								<FlexBlock justifyContent="space-between" alignItems="center" className={styles.optionTitle}>
									<FlexBlock justifyContent="space-between" alignItems="center">
										<h4>{title}</h4>
										<button onClick={() => openModal(`learnMoreModal_${optionKey}`)} className={styles.learMoreAction}>
											<InfoIcon />
										</button>
									</FlexBlock>
									<CheckboxToggle
										onChange={() => toggleOption(optionKey)}
										checked={isActive}
										uncheckedColor="#DFE0E2"
										checkedColor="#07624A"
									/>
								</FlexBlock>
								<div className={styles.optionText}>
									<p>
										{optionData?.tripsCount} {text}
									</p>
									<FlexBlock className={styles.optionFooter} columnGap="6px" alignItems="center">
										<LeafIcon /> {optionData?.savings ? +optionData.savings.toFixed(2) : 0} tons annual saving
									</FlexBlock>
								</div>
							</div>
						);
					})}
				</FlexBlock>
				<FlexBlock flexDirection="column" rowGap="22px" className={styles.chartColumn}>
					<FlexBlock columnGap="20px" justifyContent="space-between" className={styles.stats}>
						{contribution === 'company' && timePeriod === 'current-year' && stats?.target?.[contribution] !== null && (
							<Stat
								hasBackground
								title="Company target"
								subtitle="Tons of CO₂"
								amount={(stats?.target?.[contribution] && +stats.target?.[contribution].toFixed(2)) || 0}
							/>
						)}
						<Stat
							title={`${actualEmissionStatSubtitle} emissions`}
							subtitle="Tons of CO₂ / in period"
							amount={(stats?.actual?.[contribution] && +stats.actual?.[contribution].toFixed(2)) || 0}
						/>
						<Stat
							title="Emissions forecast"
							subtitle="Tons of CO₂"
							amount={(stats?.forecast?.[contribution] && +stats.forecast?.[contribution].toFixed(2)) || 0}
						/>
					</FlexBlock>
					{/*Chart*/}
					<FlexBlock flexDirection="column" className={styles.chartContainer}>
						<FlexBlock alignItems="center" columnGap="6px" className={styles.sideInfo}>
							Tons of CO2
							<ArrowDown />
						</FlexBlock>
						<ResponsiveContainer className={classNames('exclude_gw_translation', styles.chart)}>
							{/* @ts-ignore */}
							<AreaChart data={chartData} style={{ marginLeft: '-10px' }}>
								<CartesianGrid color="#ECECEC" strokeWidth={0.6} />
								<XAxis axisLine={false} tickLine={false} color="#4F4F4F" dataKey="label" tick={<CustomTick />} interval={0} />
								<YAxis
									axisLine={false}
									tickLine={false}
									type="number"
									color="#4F4F4F"
									fontSize={10}
									domain={[0, max * 1.1]}
									tickFormatter={value => Math.round(value).toLocaleString()}
									tickMargin={15}
								/>
								<Tooltip cursor={false} content={<ChartTooltip extralabel="Tons of CO2" labelMap={labelMap} />} />
								<Legend iconType="square" content={<CustomLegend />} />
								<ReferenceArea x1={forecastLabelStart} x2={forecastLabelEnds} strokeOpacity={0} fill="#fff">
									<Label
										value="Forecast"
										position="insideTopLeft"
										style={{ fill: '#000', fontWeight: '400' }}
										dx={5}
										dy={5}
										fontSize={10}
									/>
								</ReferenceArea>
								{/* @ts-ignore */}
								{budget && contribution === 'company' && timePeriod === 'current-year' && stats?.target?.[contribution] !== null && (
									<ReferenceLine y={budget} stroke="#668A87" fill="#668A87" strokeDasharray="16 16" strokeWidth={0.8}>
										<Label
											value="CO2 Budget target"
											position="insideBottomLeft"
											style={{ fill: '#687591', fontSize: '10px' }}
											dx={6}
											dy={-3}
											fontSize={10}
										/>
									</ReferenceLine>
								)}
								<Area
									type="monotone"
									dataKey="actual"
									label="Current emissions"
									stroke="#000"
									strokeWidth={3}
									fill="url(#areaGradient)"
									dot={{ r: 3, fill: '#fff', stroke: '#000' }}
									activeDot={{ r: 4, fill: '#000', stroke: '#000' }}
								/>
								<Area
									type="monotone"
									dataKey="forecast"
									label="Current emissions forecast"
									stroke="rgb(72, 182, 140)"
									strokeWidth={3}
									fill="url(#areaGradient)"
									activeDot={{ r: 4, fill: 'rgb(72, 182, 140)', stroke: 'rgb(72, 182, 140)' }}
								/>
								{map(options, ({ title, color, isActive }, key) => {
									if (!isActive) {
										return false;
									}

									return (
										<Area
											key={key}
											type="monotone"
											dataKey={key}
											label={title}
											stroke={color}
											strokeWidth={3}
											fill="transparent"
											activeDot={{ r: 4, fill: color, stroke: color }}
										/>
									);
								})}
								{/* Additional lines for indicating of forecast start */}
								<ReferenceLine x={forecastLabelStart} stroke="#000" strokeWidth={0.7}>
									<Label position="insideTop" fontSize={10} fill="#000" dy={-10} />
								</ReferenceLine>
								{chartData.length > 0 && (
									<ReferenceLine
										y={chartData.find(item => item.label === forecastLabelStart)?.forecast}
										stroke="#000"
										strokeWidth={0.7}
										strokeDasharray="8 8"
									>
										<Label position="insideLeft" fontSize={10} fill="#000" dx={10} />
									</ReferenceLine>
								)}

								<Label
									value="CO2 Budget target"
									position="insideTopLeft"
									style={{ fill: '#000' }}
									dx={10}
									dy={budget || 0}
									fontSize={10}
								/>
							</AreaChart>
						</ResponsiveContainer>
					</FlexBlock>
				</FlexBlock>
				{isError && <Overlay text="No Data Available" subtext="Error Occurred. Please try again later" />}
			</div>
		</>
	);
};

export default ForecastChart;
