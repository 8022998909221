import React, { useEffect, useMemo } from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import styles from './EmissionTargetModal.module.scss';
import { useLegacyEmissionTargetStore } from '@/store/useLegacyEmissionTargetStore.ts';
import classNames from 'classnames';
import CheckmarkIcon from '@/assets/Icons/Done-Checkmark.svg?react';
import SelectType from '@/pages/Dashboard/EmissionTargetModal/SelectType.tsx';
import SelectPeriod from '@/pages/Dashboard/EmissionTargetModal/SelectPeriod.tsx';
import SelectFiscalDate from '@/pages/Dashboard/EmissionTargetModal/SelectFiscalDate.tsx';
import ModalFooter from '@/components/ui/ModalFooter/ModalFooter.tsx';
import SelectBaseline from '@/pages/Dashboard/EmissionTargetModal/SelectBaseline.tsx';
import SelectTarget from '@/pages/Dashboard/EmissionTargetModal/SelectTarget.tsx';
import { FormikProvider, FormikTouched, useFormik } from 'formik';
import { defaultInitialValues, finishModalData, ShownHeaderSteps, STEPS, transformEmissionTargetData } from '@/constants/emissionLegacyTarget.ts';
import { createEmissionTarget, EmissionTargetResponse, getEmissionTarget } from '@/api/legacyEmissionTarget.ts';
import validationSchemaEmissionTarget from '@/validations/legacyEmissionTarget.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import WelcomeFinishModal from '@/components/WelcomeFinishModal/WelcomeFinishModal.tsx';
import CongratsIllustration from '@/assets/Illustrations/Congrats.svg?react';
import { useModalStore } from '@/store/useModalStore.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import queryKeys from '@/constants/queryKeys.ts';

export interface EmissionTargetValues extends Omit<EmissionTargetResponse, 'fiscalStartMonth' | 'fiscalStartDay'> {
	fiscalYearStart?: {
		month?: number;
		day?: number;
	};
}

const EmissionTargetModal: React.FC = () => {
	const {
		data: emissionTargetData,
		refetch: refetchEmissionTarget,
		isError,
	} = useQuery({
		queryKey: [queryKeys.getEmissionTarget],
		queryFn: async () => {
			const data = await getEmissionTarget();
			return transformEmissionTargetData(data);
		},
		enabled: false,
	});
	const { closeModal, activeModal } = useModalStore(state => state);
	const { activeStep, calculatedEmissionTargetCo2, changeStep, clickNext, clickBack, resetStore } = useLegacyEmissionTargetStore(state => state);
	const isLastStep = STEPS.length - 1 === STEPS.indexOf(activeStep);
	const isFirstStep = STEPS.indexOf(activeStep) === 0;

	const initialValues = useMemo(() => {
		if (isError || !emissionTargetData) {
			return defaultInitialValues;
		}
		const result = {
			...defaultInitialValues,
			...emissionTargetData,
		};

		if (emissionTargetData.fiscalStartMonth) {
			result.fiscalYearStart = {
				month: emissionTargetData.fiscalStartMonth,
				day: emissionTargetData.fiscalStartDay,
			};
		}

		return result;
	}, [emissionTargetData, isError]);

	const emissionTargetProvider = useFormik<EmissionTargetValues>({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchemaEmissionTarget[activeStep],
		onSubmit: async (values, formikHelpers) => {
			const transformedValues = {
				...values,
				fiscalStartMonth: values.fiscalYearStart?.month || moment().month() + 1,
				fiscalStartDay: values.fiscalYearStart?.day || moment().date(),
				targetCo2: calculatedEmissionTargetCo2,
			};

			delete transformedValues.fiscalYearStart;
			try {
				await createEmissionTarget(transformedValues);
				changeStep('finish');
				await refetchEmissionTarget();
			} catch (error) {
				handleResponseErrors(error, formikHelpers);
			}
		},
	});

	const { isSubmitting, validateForm, setTouched, handleSubmit, resetForm } = emissionTargetProvider;

	useEffect(() => {
		if (!activeModal) {
			resetStore();
			resetForm();
		}
	}, [activeModal, resetForm, resetStore]);

	const handleNext = async () => {
		const errors = await validateForm();

		if (isEmpty(errors)) {
			if (activeStep === 'target') {
				handleSubmit();
			} else {
				clickNext();
			}
		} else {
			// Get error in every field
			const touched: FormikTouched<EmissionTargetValues> = {};
			Object.keys(errors).forEach(key => {
				touched[key as keyof FormikTouched<EmissionTargetValues>] = true;
			});
			await setTouched(touched);
		}
	};

	return (
		<Modal name="emissionTargetModal" className={styles.setTargetModal}>
			{isSubmitting && <Spinner overlay absolute />}

			{/* Header stepper */}
			{!isLastStep && (
				<div className={styles.header}>
					{ShownHeaderSteps.map(({ steps, text }, index) => {
						const isActive = steps.includes(activeStep);
						const stepsIndexes = steps.map(includedStep => STEPS.findIndex(step => step === includedStep));
						const isDone = stepsIndexes.filter(stepIndex => stepIndex < STEPS.indexOf(activeStep)).length > 0;
						const status = isActive ? 'active' : isDone ? 'done' : 'future';
						return (
							<div key={`step_${index}`} className={styles.step}>
								<div
									className={classNames(styles.circle, {
										[styles.statusDone]: status === 'done',
										[styles.statusActive]: status === 'active',
										[styles.statusFuture]: status === 'future',
									})}
								>
									{status === 'done' ? <CheckmarkIcon /> : <span>{index + 1}</span>}
								</div>
								<span>{text}</span>
								{index !== ShownHeaderSteps?.length - 1 && <div className={styles.line}></div>}
							</div>
						);
					})}
				</div>
			)}

			{/*	Modal form*/}
			<FormikProvider value={emissionTargetProvider}>
				<div className={styles.modalContainer}>
					{activeStep === 'type' && <SelectType />}
					{activeStep === 'period' && <SelectPeriod />}
					{activeStep === 'fiscalDate' && <SelectFiscalDate />}
					{activeStep === 'baseline' && <SelectBaseline />}
					{activeStep === 'target' && <SelectTarget />}
					{activeStep === 'finish' && (
						<WelcomeFinishModal
							showConfetti
							img={<CongratsIllustration />}
							text={finishModalData}
							buttonProp={{
								color: 'green',
								text: 'Close',
								size: 'large',
								align: 'center',
								onClick: () => closeModal(),
							}}
						/>
					)}
				</div>
			</FormikProvider>

			{!isLastStep && !isFirstStep && (
				<ModalFooter
					className={styles.modalFooter}
					leftButton={{
						text: 'Back',
						disabled: isSubmitting,
						onClick: clickBack,
					}}
					rightButton={{
						text: activeStep === 'target' ? 'Set CO2 emission target' : 'Continue',
						hide: activeStep === 'type' || activeStep === 'period',
						onClick: handleNext,
						type: activeStep === 'target' ? 'submit' : 'button',
						disabled: isSubmitting,
					}}
				/>
			)}
		</Modal>
	);
};

export default EmissionTargetModal;
