import React, { useEffect, useState } from 'react';
import styles from './CO2Dashboard.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import BudgetEmissionChart from '@/pages/ClimateProDashboard/CO2Dashboard/BudgetEmissionChart/BudgetEmissionChart.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { AnalyticsPeriod, getDashboardAnalytics } from '@/api/dashboardAnalytics.ts';
import Select from '@/components/ui/Select/Select.tsx';
import { periodOptions } from '@/constants/climatePro.ts';
import { transformDataToGroupedOptionsArray } from '@/pages/ClimateProDashboard/Metrics/Metrics.tsx';
import { getEntitiesFilters } from '@/api/climatePro.ts';

const Co2Dashboard: React.FC = () => {
	const [timePeriod, setTimePeriod] = useState<AnalyticsPeriod>('current-year');
	const [entity, setEntity] = useState<string | undefined>(undefined);

	const { data: entitiesFilter, isSuccess } = useQuery({
		queryKey: [queryKeys.entitiesFilter],
		queryFn: getEntitiesFilters,
	});

	const query = useQuery({
		queryKey: [queryKeys.getDashboardAnalytics, timePeriod],
		queryFn: async () => {
			const [sourceModelClass, sourceModelId] = entity!.split('.');
			const data = {
				period: timePeriod,
				sourceModelClass: sourceModelClass,
				sourceModelId: +sourceModelId,
			};
			return await getDashboardAnalytics(data);
		},
		enabled: false,
	});

	useEffect(() => {
		setEntity(`${entitiesFilter?.entities[0].value}.${entitiesFilter?.entities[0].items[0].value}`);
	}, [isSuccess]);

	useEffect(() => {
		entity && query.refetch();
	}, [entity]);

	useEffect(() => {
		timePeriod && query.refetch();
	}, [timePeriod]);

	return (
		<FlexBlock flexDirection="column" rowGap="32px" className={styles.co2DashboardWrapper}>
			<h1>CO2 dashboard</h1>
			<FlexBlock justifyContent="space-between">
				<h2>Per travel type</h2>
				<FlexBlock justifyContent="space-between" columnGap="32px">
					<Select
						searchable
						value={entity}
						onChange={value => setEntity(value as string)}
						name="sourceModelId"
						options={transformDataToGroupedOptionsArray(entitiesFilter?.entities || [])}
						className={styles.selectEntityDropdown}
					/>
					<Select
						value={timePeriod}
						onChange={value => value && setTimePeriod(value as AnalyticsPeriod)}
						placeholder="Choose period"
						options={periodOptions}
						className={styles.selectDropdown}
					/>
				</FlexBlock>
			</FlexBlock>
			<Card>
				<BudgetEmissionChart queryAnalytics={query} />
			</Card>
		</FlexBlock>
	);
};

export default Co2Dashboard;
