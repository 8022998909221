// Factor Framework
import * as Yup from 'yup';

export const hotelFactorFrameworkData = ['GOODWINGS', 'HCMI', 'SQUAKE', 'DEFRA'];

export const flightFactorFrameworkData = [
	'GOODWINGS',
	'ADEME',
	'CO2-EMISSIEFACTOREN-NL',
	'DEFRA',
	'ICAO',
	'MYCLIMATE',
	'TIM',
	'US-EPA',
	'IATA',
	'GATE4',
];
export const carFactorFrameworkData = ['GOODWINGS', 'BASIC', 'ACRISS', 'US-EPA', 'EU-EEA', 'DEFRA'];
export const railFactorFrameworkData = ['GOODWINGS', 'BASIC', 'ADEME', 'SQUAKE', 'CO2-EMISSIEFACTOREN-NL'];

// Report Download
export type RangeValue = 'all' | '12months' | '6months' | '3months' | '30days' | 'custom';

export const reportRangeValues: {
	label: string;
	value: RangeValue;
}[] = [
	{
		label: 'All time',
		value: 'all',
	},
	{
		label: 'Last 12 months',
		value: '12months',
	},
	{
		label: 'Last 6 months',
		value: '6months',
	},
	{
		label: 'Last 3 months',
		value: '3months',
	},
	{
		label: 'Last 30 days',
		value: '30days',
	},
	{
		label: 'Custom',
		value: 'custom',
	},
];

export const downloadReportOptions = [
	{
		value: 'xlsx',
		label: 'Excel (xlsx)',
	},
	{
		value: 'csv',
		label: 'CSV',
	},
];

// Allocation tables
export const legalEntitiesDemoColumns = [
	{
		accessorKey: 'name',
		header: 'Legal entity name',
		type: 'text',
		validation: Yup.string().required('Legal entity name is required'),
	},
	{
		accessorKey: 'percentage',
		header: '%',
		type: 'number',
		validation: Yup.number()
			.required('Percentage is required')
			.min(0, 'Percentage cannot be negative')
			.max(100, 'Percentage cannot exceed 100'),
	},
	{
		accessorKey: 'amount',
		header: 'tCO2',
		type: 'number',
		validation: Yup.number().required('Amount is required').positive('Amount must be positive'),
	},
	{
		accessorKey: 'responsiblePerson',
		header: 'Responsible person',
		type: 'text',
		validation: Yup.string().required('Responsible person is required'),
	},
	{
		accessorKey: 'action',
		header: 'Actions',
	},
];

export const officesDemoColumns = [
	{
		accessorKey: 'name',
		header: 'Offices',
		type: 'text',
		validation: Yup.string().required('Office name is required'),
	},
	{
		accessorKey: 'percentage',
		header: '%',
		type: 'number',
		validation: Yup.number()
			.required('Percentage is required')
			.min(0, 'Percentage cannot be negative')
			.max(100, 'Percentage cannot exceed 100'),
	},
	{
		accessorKey: 'amount',
		header: 'tCO2',
		type: 'number',
		validation: Yup.number().required('Amount is required').positive('Amount must be positive'),
	},
	{
		accessorKey: 'responsiblePerson',
		header: 'Responsible person',
		type: 'text',
		validation: Yup.string().required('Responsible person is required'),
	},
	{
		accessorKey: 'action',
		header: 'Actions',
	},
];

export const departmentsDemoColumns = [
	{
		accessorKey: 'name',
		header: 'Department',
		type: 'text',
		validation: Yup.string().required('Department name is required'),
	},
	{
		accessorKey: 'percentage',
		header: '%',
		type: 'number',
		validation: Yup.number()
			.required('Percentage is required')
			.min(0, 'Percentage cannot be negative')
			.max(100, 'Percentage cannot exceed 100'),
	},
	{
		accessorKey: 'amount',
		header: 'tCO2',
		type: 'number',
		validation: Yup.number().required('Amount is required').positive('Amount must be positive'),
	},
	{
		accessorKey: 'responsiblePerson',
		header: 'Responsible person',
		type: 'text',
		validation: Yup.string().required('Responsible person is required'),
	},
	{
		accessorKey: 'action',
		header: 'Actions',
	},
];

export const periodOptions = [
	{ value: 'current-year', label: 'Current year' },
	{ value: 'last-12-months', label: 'Last 12 months' },
	{ value: 'last-6-months', label: 'Last 6 months' },
	{ value: 'last-3-months', label: 'Last 3 months' },
];
