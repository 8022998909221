import React, { useState, useEffect } from 'react';
import styles from './SwitchSelect.module.scss';
import classnames from 'classnames';

interface Option {
	label: string;
	value: string | number;
}

export interface SwitchSelectProps {
	name?: string;
	options: Option[];
	initialSelected?: string | number; // Made optional
	onChange: (value: string | number) => void;
	className?: string;
	label?: string;
	value: string | number;
	errorMessage?: string;
	touched?: boolean;
	disabled?: boolean;
	onBlur?: React.FocusEventHandler;
}

const SwitchSelect: React.FC<SwitchSelectProps> = ({
	options,
	initialSelected,
	onChange,
	name,
	className,
	label,
	errorMessage,
	touched,
	disabled,
	onBlur,
	value,
}) => {
	const [selected, setSelected] = useState<string | number>(initialSelected || value || options[0]?.value);

	useEffect(() => {
		if (initialSelected !== undefined) {
			setSelected(initialSelected);
		}
	}, [initialSelected]);

	const handleClick = (option: Option) => {
		if (selected !== option.value) {
			setSelected(option.value);
			onChange(option.value);
		}
	};

	return (
		<div className={styles.switchSelectWrapper}>
			<div className={classnames(styles.switchSelect, className)}>
				{label && <p>{label}</p>}
				{options.map(option => (
					<button
						type="button"
						key={option.value}
						id={`${name}-${option.value}`}
						name={name}
						className={classnames(styles.option, { [styles.selected]: selected === option.value })}
						onClick={() => handleClick(option)}
						onBlur={onBlur}
						disabled={disabled}
					>
						{option.label}
					</button>
				))}
				<input type="hidden" name={name} value={selected} disabled={disabled} />
				<div
					className={styles.slider}
					style={{
						width: `${95 / options.length}%`,
						left: `${options.findIndex(opt => opt.value === selected) === 0 ? 2 : (options.findIndex(opt => opt.value === selected) * 100) / options.length}%`,
					}}
				/>
			</div>
			{!!errorMessage && touched && <p className={styles.errorMessage}>{errorMessage}</p>}
		</div>
	);
};

export default SwitchSelect;
