import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.scss';
import * as Sentry from '@sentry/react';
import { setupAxios } from '@/utils/axiosUtil.ts';
import Router from '@/core/Router.tsx';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import Theme from '@/components/Theme/Theme.tsx';
import WhoamiProvider from '@/core/WhoamiProvider.tsx';
import WeglotProvider from '@/core/WeglotProvider.tsx';
import reactQueryConfig from '@/utils/reactQueryConfig.ts';
import { isIE } from 'react-device-detect';
import UnsupportedBrowser from './UnsupportedBrowser';
import RatingProvider from '@/core/RatingProvider.tsx';
import HubspotTrackingProvider from '@/core/HubspotTrackingProvider.tsx';
import { isProduction } from '@/constants/envMode.ts';
import LegacyUsage from '@/pages/errors/LegacyUsage/LegacyUsage.tsx';
import HotjarTrackingProvider from '@/core/HotjarTrackingProvider.tsx';
import ToastProvider from '@/core/ToastProvider.tsx';

if (import.meta.env.VITE_SENTRY_DSN) {
	Sentry.init({
		environment: import.meta.env.VITE_MODE,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

// Set up the axios (interceptors, handling CSRF)
setupAxios();

const renderApplication = () => {
	const root = ReactDOM.createRoot(document.getElementById('root')!);

	// Render unsupported browser message for Internet Explorer
	if (isIE) {
		return root.render(<UnsupportedBrowser />);
	}

	// Render main application
	return root.render(
		<React.StrictMode>
			<QueryClientProvider client={reactQueryConfig}>
				<WhoamiProvider />
				<WeglotProvider />
				{isProduction && <HotjarTrackingProvider />}
				{isProduction && <HubspotTrackingProvider />}
				<RatingProvider />
				<Theme mode="light">
					<Router />
				</Theme>
				<ReactQueryDevtools initialIsOpen={false} />
				<ToastProvider />
				<LegacyUsage />
			</QueryClientProvider>
		</React.StrictMode>,
	);
};

// Start the application
renderApplication();
