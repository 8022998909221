import React from 'react';
import styles from './Welcome.module.scss';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import InfoIcon from '@/assets/Icons/Info.svg?react';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';

const Welcome: React.FC = () => {
	const user = useWhoamiStore(store => store.user);

	return (
		<FlexBlock justifyContent="space-between" gap="10px" className={styles.welcomeWrapper}>
			<div>
				<h1>Welcome back, {user?.firstName} 👋🏻</h1>
				<p>My Climate gives you an overview over your contribution to the mission to travel more responsibly.</p>
			</div>
			<ButtonLink color="default" hover={false} className={styles.aboutGw} target="_blank" href={externalLinks.aboutUs}>
				<InfoIcon /> About Goodwings
			</ButtonLink>
		</FlexBlock>
	);
};

export default Welcome;
