import React from 'react';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastProvider.scss';

const ToastProvider: React.FC = () => {
	return (
		<ToastContainer
			position="bottom-right"
			hideProgressBar={false}
			newestOnTop
			closeOnClick={false}
			draggable
			pauseOnHover
			theme="colored"
			transition={Bounce}
		/>
	);
};

export default ToastProvider;
