import React, { ReactNode } from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import SideMenu from '@/components/SideMenu/SideMenu.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import { climateProSideMenuItems } from '@/constants/sideMenuContent.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import styles from './ClimateProDashboard.module.scss';
import { Outlet } from 'react-router';
import UnsavedChangesModal from '@/components/UnsavedChangesModal/UnsavedChangesModal.tsx';

interface ContentWrapperProps {
	header?: string;
	children: ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ header, children }) => {
	return (
		<div className={styles.mainContentWrapper}>
			{header && <h1>{header}</h1>}
			{children}
		</div>
	);
};

const ClimateProDashboard: React.FC = () => {
	return (
		<MainLayout>
			<Container>
				<FlexBlock columnGap="20px" className={styles.climateProWrapper}>
					<SideMenu menuItems={climateProSideMenuItems} />
					<ContentWrapper>
						<Outlet />
					</ContentWrapper>
				</FlexBlock>
			</Container>
			<UnsavedChangesModal />
		</MainLayout>
	);
};

export default ClimateProDashboard;
