import React from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import styles from './UnsavedChangesToast.module.scss';

interface UnsavedChangesToastProps {
	onCancel: () => void;
	onSubmit: () => void;
}

const UnsavedChangesToast: React.FC<UnsavedChangesToastProps> = ({ onCancel, onSubmit }) => {
	return (
		<FlexBlock justifyContent="space-between" alignItems="center" className={styles.unsavedChangesToastWrapper}>
			<FlexBlock alignItems="center" columnGap="12px">
				<InfoIcon />
				There are unsaved changes!
			</FlexBlock>
			<FlexBlock columnGap="20px">
				<Button color="default" size="medium" type="button" hover={false} className={styles.leftButton} onClick={onCancel}>
					Cancel
				</Button>
				<Button color="darkGreen" size="medium" type="submit" className={styles.rightButton} onClick={onSubmit}>
					Save
				</Button>
			</FlexBlock>
		</FlexBlock>
	);
};

export default UnsavedChangesToast;
