import React, { FocusEventHandler, useCallback } from 'react';
import classNames from 'classnames';
import styles from './Select.module.scss';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';
import { every } from 'lodash';

type Value = string | number | undefined;

export interface Option {
	value: Value;
	label: string;
}

export interface SelectProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: Value;
	onChange: (newValue: Value) => void;
	options: Option[];
	errorMessage?: string;
	touched?: boolean;
	disabled?: boolean;
	onBlur?: FocusEventHandler | undefined;
	name?: string;
}

const Select: React.FC<SelectProps> = ({
	label,
	onChange,
	placeholder,
	className,
	options,
	value,
	errorMessage,
	touched,
	disabled,
	onBlur,
	name,
}) => {
	const isNumeric = every(options, (option: Option) => typeof option.value === 'number');

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			let newValue: Value = e.target.value;

			if (isNumeric) {
				newValue = parseFloat(newValue);

				if (Number.isNaN(newValue)) {
					newValue = undefined;
				}
			}

			onChange(newValue);
		},
		[isNumeric, onChange],
	);

	return (
		<div className={classNames(styles.legacyWrapper, className)}>
			{!!label && <span className={styles.label}>{label}</span>}
			<div className={styles.selectWrapper}>
				<select className={styles.select} onChange={handleChange} value={value} disabled={disabled} onBlur={onBlur} name={name}>
					{placeholder && (
						<option value="" disabled selected={value === undefined}>
							{placeholder}
						</option>
					)}
					{options.map((option, index) => (
						<option key={index} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
				<div className={styles.icon}>
					<ArrowDown />
				</div>
			</div>
			{!!errorMessage && touched && <span className={styles.errorMessage}>{errorMessage}</span>}
		</div>
	);
};

export default Select;
