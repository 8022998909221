import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { FC } from 'react';

interface StarsRatingProps {
	rating: number;
}

const StarsRating: FC<StarsRatingProps> = ({ rating }) => {
	const wholePart = Math.floor(rating);
	const fractionalPartRating = rating - wholePart;

	const stars = Array.from({ length: wholePart + (fractionalPartRating > 0 ? 1 : 0) }, (_, index) =>
		index < wholePart ? 1 : fractionalPartRating,
	);

	return (
		<FlexBlock>
			{stars.map((starValue, index) => {
				const greenWidth = starValue * 21; // Width of the green part in pixels

				return (
					<FlexBlock key={index} alignItems="center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="15"
							height="15"
							viewBox="0 0 22 21"
							fill="none"
							style={{ marginRight: '2px' }}
						>
							<rect width="22" height="21" rx="3" ry="3" fill="#D9D9D9" />
							<rect width={greenWidth} height="21" rx="3" ry="3" fill="#0ED98C" />
							<path
								d="M8.78052 8.0128C8.78052 8.0128 6.01552 8.31905 4.17102 8.5238C4.00477 8.54392 3.85777 8.65505 3.80265 8.82392C3.74752 8.9928 3.80265 9.16867 3.92515 9.2798C5.2954 10.5302 7.35427 12.4027 7.35427 12.4027C7.35252 12.4027 6.78902 15.1265 6.41452 16.9439C6.38302 17.1084 6.44252 17.2825 6.58602 17.3867C6.72865 17.4908 6.9124 17.4925 7.05677 17.4112C8.67027 16.4942 11.087 15.1152 11.087 15.1152C11.087 15.1152 13.5046 16.4942 15.1155 17.412C15.2625 17.4925 15.4463 17.4908 15.5889 17.3867C15.7324 17.2825 15.7919 17.1084 15.7595 16.9448C15.385 15.1265 14.8224 12.4027 14.8224 12.4027C14.8224 12.4027 16.8813 10.5302 18.2515 9.28242C18.374 9.1678 18.4283 8.99192 18.374 8.82392C18.3198 8.65592 18.1728 8.5448 18.0065 8.52555C16.162 8.31905 13.3961 8.0128 13.3961 8.0128C13.3961 8.0128 12.2499 5.47705 11.486 3.78655C11.4143 3.63517 11.2638 3.5293 11.087 3.5293C10.9103 3.5293 10.7589 3.63605 10.6906 3.78655C9.9259 5.47705 8.78052 8.0128 8.78052 8.0128Z"
								fill="white"
							/>
						</svg>
					</FlexBlock>
				);
			})}
		</FlexBlock>
	);
};

export default StarsRating;
