import React, { useEffect } from 'react';
import styles from './SelectTarget.module.scss';
import { useEmissionTargetStore } from '@/store/useEmissionTargetStore.ts';
import { useFormikContext } from 'formik';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import { calculateEmissionTarget, getCarbonEmitCo2, getCarbonIntensity, selectOptions } from '@/constants/emissionTarget.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';
import moment from 'moment';
import TravelerIllustration from '@/assets/Illustrations/Traveler-Airplane.svg?react';
import classNames from 'classnames';

const SelectTarget: React.FC = () => {
	const { selectedType, calculatedEmissionTargetCo2, setCalculatedEmissionTargetCo2 } = useEmissionTargetStore();
	const { values } = useFormikContext<EmissionTargetValues>();
	const carbonEmitCo2 = getCarbonEmitCo2(
		getCarbonIntensity(values.lastYearCo2 || null, values.lastYearRevenue || null),
		values.expectedRevenue || null,
	);

	useEffect(() => {
		setCalculatedEmissionTargetCo2(
			calculateEmissionTarget(selectedType, values.targetCo2 || values.lastYearCo2, values.reductionPercentage, carbonEmitCo2),
		);
	}, [values]);

	return (
		<div className={styles.selectTargetWrapper}>
			<p className={styles.introText}>
				Defining your reduction target is a decisive action toward minimizing your environmental footprint. Your target should be ambitious
				yet achievable, reflecting your commitment to sustainability.
				<GlobalError />
			</p>

			{selectedType === 'intensity' && (
				<FlexBlock flexDirection="column" rowGap="72px" className={classNames(styles.targetWrapper, styles.expectedRevenue)}>
					<FlexBlock justifyContent="space-between" alignItems="start">
						<FlexBlock columnGap="10px" alignItems="center">
							<h2>Enter your expected revenue for the current period</h2>
							<Tooltip
								placement="right"
								text="Estimate and enter your expected revenue for this full year in millions of dollars. This projection is a necessary step in calculating emissions based on intensity."
							/>
						</FlexBlock>
						<FormikInput type="number" name="expectedRevenue" placeholder="Expected revenue in millions of dollars *" min={0.0001} />
					</FlexBlock>
					<InfoStatus
						status="warning"
						text={`With no action leading to carbon reductions, your company will emit 
							 ${carbonEmitCo2} tons of CO2e in the current period.`}
					/>
				</FlexBlock>
			)}
			<FlexBlock flexDirection="column" rowGap="72px" className={styles.targetWrapper}>
				<FlexBlock justifyContent="space-between" alignItems="start">
					<FlexBlock columnGap="10px" alignItems="center">
						<h2>Select reduction percentage</h2>
						<Tooltip
							placement="right"
							text="Provide your company's revenue from the last full year in millions of dollars. This will allow us to establish the ratio of emissions to economic output."
						/>
					</FlexBlock>
					<FormikSelect name="reductionPercentage" options={selectOptions} className={styles.selectField} />
				</FlexBlock>
				<InfoStatus
					status="success"
					text={`You current carbon intensity is your selection will set a benchmark for future efforts and progress tracking. Aim for a target that aligns with industry standards and your company’s sustainability vision. ${calculatedEmissionTargetCo2} tons of CO2e per million dollar in revenue.`}
				/>
			</FlexBlock>

			<FlexBlock justifyContent="space-between" columnGap="20px" className={styles.mainCalculationWrapper}>
				<div className={styles.co2Target}>
					<FlexBlock justifyContent="space-between" alignItems="start">
						<div className={styles.header}>
							<p>Company reduction target</p>
							<p>Tons of CO₂</p>
						</div>
						<span className={styles.year}>{moment().year()}</span>
					</FlexBlock>
					<p className={styles.calculation}>{calculatedEmissionTargetCo2}</p>
				</div>
				<div className={styles.imageWrapper}>
					<TravelerIllustration />
				</div>
			</FlexBlock>
		</div>
	);
};

export default SelectTarget;
