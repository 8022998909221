import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import ArrowUp from '@/assets/Icons/Arrow-Up.svg?react';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';
import styles from './SideMenu.module.scss';
import { NavLink } from 'react-router-dom';
import { each } from 'lodash';

interface SubmenuItem {
	path: string;
	icon?: ReactNode;
}

interface MenuItem {
	icon: ReactNode;
	submenu?: Record<string, SubmenuItem>;
	path?: string;
}

interface SideMenuProps {
	menuItems: Record<string, MenuItem>;
}

const SideMenu: FC<SideMenuProps> = ({ menuItems }) => {
	const [openSubmenus, setOpenSubmenus] = useState<Record<string, boolean>>({});
	const submenuRefs = useRef<Record<string, HTMLDivElement | null>>({});

	const toggleSubmenu = (label: string) => {
		setOpenSubmenus(prev => ({
			...prev,
			[label]: !prev[label],
		}));
	};

	const getSubmenuStyle = (label: string) => {
		if (openSubmenus[label] && submenuRefs.current[label]) {
			return { height: `${submenuRefs.current[label]!.scrollHeight}px` };
		}
		return { height: '0' };
	};

	useEffect(() => {
		// Open submenu if that submenu contains currently active link
		const newOpenSubmenus: Record<string, boolean> = {};
		Object.entries(menuItems).forEach(([label, item]) => {
			if (item.submenu) {
				each(item.submenu, subItem => {
					if (location.pathname === subItem.path) {
						newOpenSubmenus[label] = true;
					}
				});
			}
		});
		setOpenSubmenus(newOpenSubmenus);
	}, [menuItems]);

	return (
		<div className={styles.sideMenu}>
			{Object.entries(menuItems).map(([label, item]) => (
				<div key={label} className={styles.menuItem}>
					{item.submenu ? (
						// If menu item has submenu options
						<div
							className={classnames(styles.menuTitle, {
								[styles.activeLink]: location.pathname === item.path,
								[styles.open]: openSubmenus[label],
							})}
							onClick={() => toggleSubmenu(label)}
						>
							<span className={styles.icon}>{item.icon}</span>
							<span>{label}</span>
							<span className={styles.arrowIcon}>{openSubmenus[label] ? <ArrowUp /> : <ArrowDown />}</span>
						</div>
					) : (
						<NavLink
							to={item.path || '#'}
							className={({ isActive }) =>
								classnames(styles.menuTitle, {
									[styles.activeLink]: isActive,
								})
							}
							// onClick={}
						>
							<span className={styles.icon}>{item.icon}</span>
							<span>{label}</span>
						</NavLink>
					)}

					{item.submenu && (
						<div
							ref={(el: HTMLDivElement | null) => {
								submenuRefs.current[label] = el;
							}}
							style={getSubmenuStyle(label)}
							className={styles.submenu}
						>
							{Object.entries(item.submenu).map(([subLabel, subItem]) => (
								<NavLink
									key={subLabel}
									to={subItem.path}
									className={({ isActive }) =>
										classnames(styles.submenuItem, {
											[styles.activeLink]: isActive,
										})
									}
								>
									{subItem.icon && <span className={styles.icon}>{subItem.icon}</span>}
									{subLabel}
								</NavLink>
							))}
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default SideMenu;
