import React, { useEffect } from 'react';
import styles from './SelectBaseline.module.scss';
import { useEmissionTargetStore } from '@/store/useEmissionTargetStore.ts';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import { useFormikContext } from 'formik';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import { getCarbonIntensity } from '@/constants/emissionTarget.ts';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import InfoStatus from '@/components/ui/InfoStatus/InfoStatus.tsx';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';

const SelectBaseline: React.FC = () => {
	const { carbonIntensity, setCarbonIntensity } = useEmissionTargetStore(state => state);
	const { values } = useFormikContext<EmissionTargetValues>();

	const { selectedType } = useEmissionTargetStore();

	useEffect(() => {
		values.lastYearCo2 &&
			values.lastYearRevenue &&
			setCarbonIntensity(getCarbonIntensity(values.lastYearCo2, values.lastYearRevenue));
	}, [setCarbonIntensity, values]);

	return (
		<div className={styles.selectBaselineWrapper}>
			<div className={styles.content}>
				<p className={styles.introText}>
					Establishing a baseline is a critical step in setting your emission target. It provides a reference
					point from which to measure your progress.
				</p>
				<FlexBlock justifyContent="space-between" alignItems="start" className={styles.baselineWrapper}>
					<FlexBlock columnGap="10px" alignItems="center">
						<h2>Enter your travel emissions from last {selectedType === 'absolute' ? 'year' : 'period'}</h2>
						<Tooltip
							placement="right"
							text="Input the total travel emissions (in metric tons of CO2e) from your last full reporting year. This quantifies the environmental impact of your business travel and lays the foundation for setting reduction goals."
						/>
					</FlexBlock>
					<FormikInput
						type="number"
						placeholder="Emissions in metric tons of CO2e *"
						name="lastYearCo2"
						min={0.0001}
						className={styles.inputField}
					/>
				</FlexBlock>

				{selectedType === 'intensity' && (
					<FlexBlock justifyContent="space-between" alignItems="start" className={styles.baselineWrapper}>
						<FlexBlock columnGap="10px" alignItems="center">
							<h2>Enter your revenue from last period</h2>
							<Tooltip
								placement="right"
								text="Provide your company's revenue from the last full year in millions of dollars. This will allow us to establish the ratio of emissions to economic output."
							/>
						</FlexBlock>
						<FormikInput
							type="number"
							name="lastYearRevenue"
							placeholder="Revenue in millions of dollars *"
							min={0.0001}
							className={styles.inputField}
						/>
					</FlexBlock>
				)}
				<InfoStatus
					text={`You current carbon intensity is ${carbonIntensity ? carbonIntensity : 0} tons of CO2e per million dollar in revenue`}
					className={styles.infoWrapper}
				/>
			</div>
		</div>
	);
};

export default SelectBaseline;
