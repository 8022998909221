const queryKeys = {
	getDashboardAnalytics: 'getDashboardAnalytics',
	getEmissionTarget: 'getEmissionTarget',
	getEmployeeOnboardOrganizations: 'employeeOnboardOrganization',
	usersOrganizations: 'usersOrganizations',
	upcomingTrips: 'upcomingTrips',
	suggestedRestaurants: 'suggestedRestaurants',
	suggestedPlacesToRelax: 'suggestedPlacesToRelax',
	transportationToHotel: 'transportationToHotel',
	showRatingModal: 'showRatingModal',
	deleteEmissionTargetData: 'deleteEmissionTargetData',
	searchTerm: 'searchTerm',
	climateProNotificationSettings: 'climateProNotificationSettings',
	certificates: 'certificates',
	factorFramework: 'factorFramework',
	allocationEntities: 'allocationEntities',
	responsibleUsers: 'responsibleUsers',
	allocationTables: 'allocationTables',
	metrics: 'metrics',
	entitiesFilter: 'entitiesFilter',
};

export default queryKeys;
