import React from 'react';
import styles from './Certificates.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import CertificateIllustration from '@/assets/Illustrations/Certificate-With-Background.svg?react';
import DownloadIcon from '@/assets/Icons/Download.svg?react';
import ShareIcon from '@/assets/Icons/Share.svg?react';
import { CellContext } from '@tanstack/react-table';
import { useModalStore } from '@/store/useModalStore.ts';
import ShareModal from '@/pages/ClimateProDashboard/Certificates/ShareModal.tsx';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { getCertificates } from '@/api/climatePro.ts';
import { formatWeight } from '@/utils/stringUtils.ts';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import Tooltip from '@/components/ui/Tooltip/Tooltip.tsx';
import ClimateProCertificateData = App.Data.ClimateProCertificateData;

const certificatesDemoColumns = [
	{
		accessorKey: 'year',
		header: 'Year',
		type: 'number',
	},
	{
		accessorKey: 'issueDate',
		header: 'Date issued',
		type: 'text',
		cell: ({ getValue }: CellContext<any, string>) => {
			const value = getValue();
			return moment(value).format('DD MMM YYYY');
		},
	},
	{
		accessorKey: 'co2eConsumed',
		header: 'CO2e consumed',
		type: 'number',
		cell: ({ getValue }: CellContext<any, number | string>) => {
			const value = getValue();
			return formatWeight(+value);
		},
	},
	{
		accessorKey: 'co2eRemoved',
		header: 'CO2e removed',
		type: 'number',
		cell: ({ getValue }: CellContext<any, number>) => {
			const value = getValue();
			return formatWeight(value);
		},
	},
	{
		accessorKey: 'co2eReduced',
		header: 'CO2e reduced',
		type: 'number',
		cell: ({ getValue }: CellContext<any, string | number | null>) => {
			const value = getValue();
			return value ? formatWeight(+value) : 'N/A';
		},
	},
	{
		accessorKey: 'reduceUrl',
		header: 'Reduction link',
		type: 'text',
		cell: ({ getValue }: CellContext<any, string>) => {
			const url = getValue();
			return url ? (
				<a href={url} target="_blank" className={styles.tableLinkCertificates}>
					link
				</a>
			) : (
				'N/A'
			);
		},
	},
	{
		accessorKey: 'removeUrl',
		header: 'Removal link',
		type: 'text',
		cell: ({ getValue }: CellContext<any, string>) => {
			const url = getValue<string>();
			return url ? (
				<a href={url} target="_blank" className={styles.tableLinkCertificates}>
					link
				</a>
			) : (
				'N/A'
			);
		},
	},
	{
		accessorKey: 'ownerEntity',
		header: 'Owner',
		type: 'text',
	},
];

const Certificates: React.FC = () => {
	const { openModal } = useModalStore(store => store);

	const { data, isSuccess, isLoading } = useQuery({
		queryKey: [queryKeys.certificates],
		queryFn: getCertificates,
	});

	const handleDownload = (rowId: number) => {
		const url = data?.certificates.find(certificate => certificate.id === rowId)?.downloadUrl;
		window.open(url, '_blank');
	};

	const handleShare = (certificateId: number) => {
		openModal('certificateShareModal', {
			downloadUrl: data?.certificates.find(certificate => certificate.id.toString() === certificateId.toString())
				?.downloadUrl,
		});
	};

	const additionalActions: any[] = [
		{
			icon: <DownloadIcon />,
			onClick: handleDownload,
			tooltipText: 'Download',
		},
		{
			icon: <ShareIcon />,
			onClick: handleShare,
			tooltipText: 'Share',
		},
	];

	return (
		<FlexBlock flexDirection="column" rowGap="32px" className={styles.certificatesWrapper}>
			<h1>Climate impact certificates</h1>
			<p className={styles.additionalInfo}>
				Get an overview of your company’s travel emissions with a detailed report.
			</p>
			<Card className={styles.certificatesCard}>
				<FlexBlock columnGap="20px" justifyContent="space-between">
					<div>
						<h2>What is a climate impact certificate?</h2>
						<p>
							A climate impact certificate (CO2 certificate) quantifies your company’s carbon emissions,
							ensuring transparency and demonstrating your commitment to sustainability.
						</p>
						<ButtonLink
							color="default"
							size="medium"
							href="https://static.goodwings.com/pages/dashboard/certificate_2023_example.pdf"
							target="_blank"
						>
							Download sample certificate
						</ButtonLink>
					</div>
					<div>
						<CertificateIllustration />
					</div>
				</FlexBlock>
			</Card>
			{isLoading && (
				<div className={styles.tableSection}>
					<Skeleton width="140px" height="22px" style={{ marginBottom: '30px', marginTop: '10px' }} />
					<Skeleton width="100%" height="300px" />
				</div>
			)}
			{isSuccess && data?.certificates && (
				<FlexBlock flexDirection="column" rowGap="32px" className={styles.tableSection}>
					<h2>Certificates</h2>
					<div className={styles.tableWrapper}>
						<table>
							<thead>
								<tr>
									{certificatesDemoColumns.map((column, index) => (
										<th key={index}>{column.header}</th>
									))}
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.certificates.map(certificate => (
									<tr key={certificate.id}>
										{certificatesDemoColumns.map((column, index) => (
											<td key={index}>
												{column.cell
													? column.cell({
															getValue: () =>
																certificate[
																	column.accessorKey as keyof ClimateProCertificateData
																] || '',
														} as any)
													: certificate[
															column.accessorKey as keyof ClimateProCertificateData
														]}
											</td>
										))}
										<td>
											<FlexBlock columnGap="10px">
												{additionalActions.map(action => (
													<Tooltip
														icon={
															<button
																onClick={() => action.onClick(certificate.id)}
																className={styles.actionBttn}
															>
																{action.icon}
															</button>
														}
														text={action.tooltipText}
													/>
												))}
											</FlexBlock>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</FlexBlock>
			)}
			<ShareModal />
		</FlexBlock>
	);
};

export default Certificates;
