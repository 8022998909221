export const calculateTextHeight = (text: string, width: number): number => {
	const tempElement = document.createElement('div');

	// Imitating select option style
	tempElement.style.position = 'absolute';
	tempElement.style.left = '-9999px';
	tempElement.style.width = `${width}px`;
	tempElement.style.visibility = 'hidden';
	tempElement.style.paddingTop = '12px';
	tempElement.style.paddingBottom = '12px';
	tempElement.style.paddingLeft = '12px';
	tempElement.style.paddingRight = '18px';
	tempElement.style.fontSize = '16px';

	tempElement.textContent = text;
	document.body.appendChild(tempElement);

	// Getting the height of the option element
	const elementHeight = window.getComputedStyle(tempElement).getPropertyValue('height');
	const height = Number(elementHeight.slice(0, -2));

	document.body.removeChild(tempElement);

	return height;
};
