import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import ChangePassword from './ChangePassword';
import TwoFaAuth from './TwoFaAuth';
import styles from './SecuritySettings.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import React from 'react';

const SecuritySettings: React.FC = () => {
	return (
		<MainLayout>
			<div className={styles.securitySettingsWrapper}>
				<Container>
					<FlexBlock flexDirection="column" rowGap="40px">
						<ChangePassword />
						<TwoFaAuth />
					</FlexBlock>
				</Container>
			</div>
		</MainLayout>
	);
};

export default SecuritySettings;
