import React, { useState, useRef, useEffect } from 'react';
import styles from './Accordion.module.scss';
import IconDown from '@/assets/Icons/Arrow-Down.svg?react';
import classNames from 'classnames';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

interface AccordionProps {
	title: string;
	children: React.ReactNode;
	leftIcon?: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children, leftIcon }) => {
	const [isOpen, setIsOpen] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const content = contentRef.current;
		if (content) {
			const resizeObserver = new ResizeObserver(() => {
				if (isOpen) {
					content.style.maxHeight = `${content.scrollHeight}px`;
				}
			});

			resizeObserver.observe(content);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [isOpen, children]);

	useEffect(() => {
		const content = contentRef.current;
		if (content) {
			if (isOpen) {
				content.style.maxHeight = `${content.scrollHeight}px`;
			} else {
				content.style.maxHeight = '0px';
			}
		}
	}, [isOpen]);

	return (
		<div className={classNames(styles.accordion, { [styles.open]: isOpen })}>
			<button className={styles.accordionToggle} onClick={toggleAccordion}>
				<FlexBlock alignItems="center">
					{leftIcon && (
						<FlexBlock alignItems="center" justifyContent="center" className={styles.iconWrapper}>
							{leftIcon}
						</FlexBlock>
					)}
					<span className={styles.title}>{title}</span>
				</FlexBlock>
				<div className={styles.arrowIconWrapper}>
					<IconDown className={classNames(styles.chevron, { [styles.chevronOpen]: isOpen })} />
				</div>
			</button>
			<div className={styles.accordionContent} ref={contentRef}>
				<div className={styles.accordionInner}>{children}</div>
			</div>
		</div>
	);
};

export default Accordion;
