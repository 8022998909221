import React, { ReactNode } from 'react';
import {
	Button,
	OverlayArrow,
	Tooltip as TooltipReactAria,
	TooltipTrigger,
	TooltipProps as TooltipReactAriaProps,
} from 'react-aria-components';
import InfoIcon from '@/assets/Icons/Info-Line.svg?react';
import styles from './Tooltip.module.scss';
import classNames from 'classnames';

interface TooltipProps {
	icon?: ReactNode;
	text: string;
	className?: string;
	delay?: number;
	offset?: number;
	placement?: 'top' | 'bottom' | 'left' | 'right';
	isDisabled?: boolean;
	defaultOpen?: boolean;
}

interface MyTooltipProps extends Omit<TooltipReactAriaProps, 'children'> {
	children: React.ReactNode;
}

const MyTooltip = ({ children, ...props }: MyTooltipProps) => {
	return (
		<TooltipReactAria {...props}>
			<OverlayArrow className={styles.reactAriaOverlayArrow}>
				<svg width={10} height={10} viewBox="0 0 8 8">
					<path d="M0 0 L4 4 L8 0" />
				</svg>
			</OverlayArrow>
			{children}
		</TooltipReactAria>
	);
};

const Tooltip: React.FC<TooltipProps> = ({
	icon = <InfoIcon />,
	text,
	className,
	delay = 5.5,
	offset = 0,
	placement = 'top',
	isDisabled = false,
	defaultOpen = false,
}) => {
	return (
		<TooltipTrigger delay={delay} isDisabled={isDisabled} defaultOpen={defaultOpen}>
			<Button className={styles.iconWrapper}>{icon}</Button>
			<MyTooltip placement={placement} offset={offset} className={classNames(styles.reactAriaTooltip, className)}>
				{text}
			</MyTooltip>
		</TooltipTrigger>
	);
};

export default Tooltip;
