import React from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import styles from './WarningModal.module.scss';

const WarningModal: React.FC = () => {
	const { closeModal, data } = useModalStore(state => state);

	return (
		<Modal name="warningFactorFrameworkModal" className={styles.warningFactorFrameworkWrapper}>
			<h3>Confirm Carbon Framework Change</h3>
			<p className={styles.text}>
				Each of the Factor Frameworks can only be updated <span>once per year</span>. Are you sure you want to
				proceed? This action cannot be undone for the next 12 months.
			</p>
			<FlexBlock justifyContent="space-between" alignItems="center" className={styles.buttonsWrapper}>
				<Button hover={false} size="medium" onClick={closeModal}>
					Cancel
				</Button>
				<Button
					size="medium"
					color="darkGreen"
					onClick={() => {
						data();
						closeModal();
					}}
				>
					Confirm
				</Button>
			</FlexBlock>
		</Modal>
	);
};

export default WarningModal;
