import { create } from 'zustand';

interface ModalStoreTypes {
	activeModal: null | string;
	openModal: (modalName: string, data?: any) => void;
	closeModal: () => void;
	data?: any;
}

export const useModalStore = create<ModalStoreTypes>(set => ({
	activeModal: null,
	openModal: (modalName: string, data: any = undefined) =>
		set({
			activeModal: modalName,
			data,
		}),
	closeModal: () =>
		set({
			activeModal: null,
		}),
}));
