import React, { useEffect } from 'react';
import styles from './SelectTarget.module.scss';
import SelectHeader from '@/pages/Dashboard/EmissionTargetModal/SelectHeader.tsx';
import TargetIcon from '@/assets/Illustrations/Target.svg?react';
import { useLegacyEmissionTargetStore } from '@/store/useLegacyEmissionTargetStore.ts';
import lightImg from '@/assets/Illustrations/Light.svg';
import { useFormikContext } from 'formik';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import FormikSelect from '@/components/Formik/FormikSelect.tsx';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import {
	calculateEmissionTarget,
	getCarbonEmitCo2,
	getCarbonIntensity,
	selectOptions,
} from '@/constants/emissionLegacyTarget.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';

const SelectTarget: React.FC = () => {
	const { selectedType, calculatedEmissionTargetCo2, setCalculatedEmissionTargetCo2 } =
		useLegacyEmissionTargetStore();
	const { values } = useFormikContext<EmissionTargetValues>();
	const carbonEmitCo2 = getCarbonEmitCo2(
		getCarbonIntensity(values.lastYearCo2 || null, values.lastYearRevenue || null),
		values.expectedRevenue || null,
	);

	useEffect(() => {
		setCalculatedEmissionTargetCo2(
			calculateEmissionTarget(
				selectedType,
				values.targetCo2 || values.lastYearCo2,
				values.reductionPercentage,
				carbonEmitCo2,
			),
		);
	}, [values]);

	return (
		<div className={styles.selectTarget}>
			<SelectHeader
				icon={<TargetIcon />}
				title="Your Emissions Reduction Target"
				text="Defining your reduction target is a decisive action toward minimizing your environmental footprint. Your target should be ambitious yet achievable, reflecting your commitment to sustainability."
			/>

			<div className={styles.content}>
				<GlobalError />

				{selectedType === 'absolute' ? (
					<>
						<h3>Select reduction percentage</h3>
						<p>
							This percentage reflects your commitment to reducing emissions intensity relative to your
							emissions from last year.
						</p>
						<FormikSelect
							label="Reduction"
							name="reductionPercentage"
							options={selectOptions}
							className={styles.inputField}
						/>
					</>
				) : (
					<>
						<h3>Enter your expected revenue for the current period</h3>
						<p>
							Estimate and enter your expected revenue for this full year in millions of dollars. This
							projection is a necessary step in calculating emissions based on intensity.
						</p>
						<FormikInput
							type="number"
							name="expectedRevenue"
							label="Expected revenue in millions of dollars"
							placeholder="Enter revenue in millions"
							min={0.0001}
							color="gray"
							className={styles.inputField}
						/>

						<p>
							With no action leading to carbon reductions, your company will emit
							<strong> {carbonEmitCo2}</strong> tons of CO2 in the current period.
						</p>
					</>
				)}

				{selectedType === 'intensity' && (
					<>
						<h3>Select reduction percentage</h3>
						<p>
							This percentage reflects your commitment to reducing emissions intensity relative to
							economic output.
						</p>
						<FormikSelect
							label="Reduction"
							name="reductionPercentage"
							options={selectOptions}
							className={styles.inputField}
						/>
						<div className={styles.infoBox}>
							<div>
								<img src={lightImg} />
							</div>
							<p>
								Your selection will set a benchmark for future efforts and progress tracking. Aim for a
								target that aligns with industry standards and your company’s sustainability vision.
							</p>
						</div>
					</>
				)}
				<div className={styles.co2Target}>
					<div>Your CO2 target</div>
					<h1>{calculatedEmissionTargetCo2} tons</h1>
				</div>
			</div>
		</div>
	);
};

export default SelectTarget;
