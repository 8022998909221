import { round } from 'lodash';
import { Option } from '@/components/ui/SelectLegacy/Select.tsx';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import { EmissionTargetResponse, EmissionTargetType } from '@/api/legacyEmissionTarget.ts';

export const STEPS = ['type', 'period', 'fiscalDate', 'baseline', 'target', 'finish'] as const;

export type StepType = (typeof STEPS)[number];
type StepsProps = { text: string; steps: StepType[] }[];
export const ShownHeaderSteps: StepsProps = [
	{
		text: 'Target type',
		steps: ['type'],
	},
	{
		text: 'Time Period',
		steps: ['period', 'fiscalDate'],
	},
	{
		text: 'Baseline',
		steps: ['baseline'],
	},
	{
		text: 'Set Target',
		steps: ['target'],
	},
];

// Initial values for formik
export const defaultInitialValues: EmissionTargetValues = {
	reductionPercentage: 5,
};

// Transform data to get fiscalStartYear object needed for CalendarPicker in fiscalYear step
export const transformEmissionTargetData = (data: EmissionTargetResponse) => {
	if (data?.fiscalStartDay && data.fiscalStartMonth) {
		return {
			...data,
			fiscalStartYear: {
				day: data.fiscalStartDay,
				month: data.fiscalStartMonth,
			},
		};
	}
	return data;
};

export const calculateEmissionTarget = (
	type?: EmissionTargetType,
	targetCo2?: number | null,
	reductionPercentage?: number | null,
	getCarbonEmitCo2?: number | null,
) => {
	if (type === 'absolute' && targetCo2 && reductionPercentage) {
		return round(targetCo2 * ((100 - reductionPercentage) / 100), 2);
	}

	if (type === 'intensity' && getCarbonEmitCo2 && reductionPercentage) {
		return round(getCarbonEmitCo2 * ((100 - reductionPercentage) / 100), 2);
	}
	return 0;
};

export const getCarbonEmitCo2 = (carbonIntensity: number | null, expectedRevenue: number | null) => {
	if (!carbonIntensity || !expectedRevenue || carbonIntensity === 0) {
		return 0;
	}

	return round(expectedRevenue * carbonIntensity, 2);
};

export const getCarbonIntensity = (targetCo2: number | null, lastYearRevenue: number | null) => {
	if (!targetCo2 || !lastYearRevenue) {
		return 0;
	}

	return round(targetCo2 / lastYearRevenue, 2);
};

export const selectOptions: Option[] = Array.from({ length: 20 }, (_, index) => {
	const value = index * 5;
	return {
		label: `${value}% reduction`,
		value,
	};
});

export const finishModalData = {
	paragraphs: [
		{
			title: 'You did it!',
		},
		{
			desc: 'Congratulations on setting your CO2 emissions target!',
		},
		{
			desc: `Thank you for leading the way in environmental responsibility. Let’s
      continue this journey towards a greener future together!`,
		},
	],
};
