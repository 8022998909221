import * as Yup from 'yup';

export const legalEntitiesValidationSchema = Yup.object().shape({
	legalEntities: Yup.array().of(
		Yup.object().shape({
			modelId: Yup.number().required('Legal entity is required'),
			percentage: Yup.number()
				.typeError('Percentage must be a number')
				.required('Percentage is required')
				.min(0, 'Percentage cannot be negative')
				.max(100, 'Percentage cannot exceed 100'),
			targetCo2: Yup.number().required('tCO2 amount is required').min(0, 'tCO2 must be positive'),
			responsibleUserId: Yup.number().required('Responsible person is required'),
		}),
	),
});
